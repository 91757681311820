import { Injectable } from "@angular/core";

import { BehaviorSubject, Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import { ICartItems } from "../ITypes";
import { IQuery } from "src/app/core/ITypes";
import {
  ApiService,
  CacheService,
  CentralStorageService,
  SiteConfigService,
} from "src/app/core/services";
import { isEmpty } from "lodash";
import { UserService } from "./user.service";

@Injectable()
export class ProductService {
  // default
  browserLang: string = "default"; //change to default, en, fi, etc..
  nextPage: string;
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private detailLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private refetchProductListing = new BehaviorSubject<boolean>(false);
  refetchProductListing$ = this.refetchProductListing.asObservable();
  tenant: string = "";

  constructor(
    private api: ApiService,
    private siteConfigService: SiteConfigService,
    private centralStorageService: CentralStorageService,
    private userService: UserService,
    private cacheService: CacheService
  ) {
    this.tenant = this.siteConfigService.siteConfig.tenant;
  }

  observeLoading() {
    return this.loading.asObservable();
  }

  observeDetailLoading() {
    return this.detailLoading.asObservable();
  }

  setRefetchProductListing() {
    this.refetchProductListing.next(true);
  }

  async getProducts(
    query?: IQuery,
    loading: boolean = true,
    relatedProducts?: string
  ) {
    try {
      if (this.nextPage === null && query.page != "1") {
        return [];
      }
      this.loading.next(loading);

      let queryURL = "/products";
      if (relatedProducts && relatedProducts.length) {
        queryURL += relatedProducts;
      }

      const { zip_code, delivery_date, delivery_method } =
        await this.centralStorageService.getKeysForPurchaseBtn();
      const cart = this.cacheService.get("cart");
      const vendorCode = this.centralStorageService.getVendorCode();
      const iQuery = {
        ...query,
        zip_code,
        delivery_date,
        delivery_method: delivery_method?.code || null,
        user_uuid: this.userService.getUserUUID,
        cart_id: cart && cart.id,
        vendor_tag: vendorCode,
      };

      const { status_code, result } = await this.api
        .get(queryURL, iQuery)
        .toPromise();

      this.nextPage = result.next_page_url;
      this.loading.next(false);
      if (status_code === 200 && !isEmpty(result)) {
        return result.data.map((d: ICartItems, index) => {
          let summary = d.summary[this.browserLang];
          summary.isPurchasable = d.isPurchasable;
          summary.vendor = d.vendor;

          // change below condition when multiple regions are available
          if (d && d.currentBookings && summary.F030 && summary.F030[0].F031) {
            summary.F030[0].currentBookings = d.currentBookings;
          }

          return summary;
        });
      }
      return [];
    } catch (error) {
      this.loading.next(false);
      throw error;
    }
  }

  async getFeaturedProducts(query?: IQuery, loading: boolean = true) {
    try {
      this.loading.next(loading);

      const { zip_code, delivery_date, delivery_method } =
        await this.centralStorageService.getKeysForPurchaseBtn();
      const cart = this.cacheService.get("cart");
      const vendorCode = this.centralStorageService.getVendorCode();
      const iQuery = {
        ...query,
        zip_code,
        delivery_date,
        delivery_method: delivery_method?.code || null,
        user_uuid: this.userService.getUserUUID,
        cart_id: cart && cart?.id,
        vendor_tag: vendorCode,
      };
      const { status_code, result } = await this.api
        .get("/products/featured", iQuery)
        .toPromise();

      this.loading.next(false);
      if (status_code === 200 && result && !isEmpty(result)) {
        // return result.data.map((d: ICartItems) => d.summary[this.browserLang]);
        return result.data.map((d: ICartItems) => {
          let summary = d.summary[this.browserLang];

          // change below condition when multiple regions are available
          if (d && d.currentBookings && summary.F030 && summary.F030[0].F031) {
            summary.F030[0].currentBookings = d.currentBookings;
          }

          return summary;
        });
      }
      return [];
    } catch (error) {
      this.loading.next(false);
      throw error;
    }
  }

  async getProduct(id: string, query?: IQuery) {
    try {
      this.detailLoading.next(true);

      const { zip_code, delivery_date, delivery_method } =
        await this.centralStorageService.getKeysForPurchaseBtn();
      const cart = this.cacheService.get("cart");
      const vendorCode = this.centralStorageService.getVendorCode();
      const iQuery = {
        ...query,
        zip_code,
        delivery_date,
        delivery_method: delivery_method?.code || null,
        user_uuid: this.userService.getUserUUID,
        cart_id: cart && cart?.id,
        vendor_tag: vendorCode,
      };
      const { status_code, result } = await this.api
        .getById("/products", id, iQuery)
        .toPromise();

      this.detailLoading.next(false);
      if (status_code === 200 && !isEmpty(result)) {
        // return result.summary[this.browserLang];
        // return result.data.map((d: ICartItems) => {
        let summary = result.summary[this.browserLang];

        // change below condition when multiple regions are available
        if (
          result &&
          result.currentBookings &&
          summary.F030 &&
          summary.F030[0].F031
        ) {
          summary.F030[0].currentBookings = result.currentBookings;
        }
        summary.isPurchasable = result.isPurchasable;

        return summary;
        // });
      }
      return {};
    } catch (error) {
      this.detailLoading.next(false);
      throw error;
    }
  }

  async getProductsStock(productIds = [], loading: boolean = true) {
    try {
      // this.loading.next(loading);

      let data = { products: productIds };
      const { status_code, result } = await this.api
        .post(data, "/products/check-stock")
        .toPromise();

      // this.loading.next(false);
      if (status_code === 200 && result && !isEmpty(result)) {
        // console.log("RESULT OF STOCKKK !!! ", result);
        return result;
      }
      return [];
    } catch (error) {
      // this.loading.next(false);
      throw error;
    }
  }

  async checkProductAvailability(id: string, query?: IQuery) {
    try {
      const { zip_code, delivery_date, delivery_method } =
        await this.centralStorageService.getKeysForPurchaseBtn();
      const cart = this.cacheService.get("cart");
      const iQuery = {
        ...query,
        zip_code,
        delivery_date,
        delivery_method: delivery_method?.code || null,
        user_uuid: this.userService.getUserUUID,
        cart_id: cart && cart?.id,
      };
      const { status_code, result } = await this.api
        .getById("/products/check-availability", id, iQuery)
        .toPromise();

      if (status_code === 200 && !isEmpty(result)) {
        return result;
      }
      return {};
    } catch (error) {
      throw error;
    }
  }
}
