import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LayoutComponent } from "src/app/layout/layout.component";

/**
 * Represents Routes & Layout
 * as the main app wrapper
 * @exports routes
 */
const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    loadChildren: () =>
      import("../../layout/layout.module").then((m) => m.LayoutModule),
    data: {
      title: "home",
      breadcrumb: [
        {
          label: "Home",
          url: "/",
        },
      ],
    },
  },
  {
    path: "**",
    redirectTo: "/",
  },
];

/**
 * Represents Routing module
 * @export RoutingModule
 * @class RoutingModule
 */
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabled",
      scrollPositionRestoration: "top",
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class RoutingModule {}
