<div class="c-headerNotbarContainer" *ngIf="displayNotificationBar" [ngClass]="{
    'c-success': ['initial_cuttoff_time', 'cuttoff_time_x_min_left', 'cuttoff_time_delvery_date_pushed_further'].includes(step),
    'c-warning': step === 'delivery_available',
    'c-danger': step === 'collico_not_available'
  }">
  <div class="l-page">
    <div class="c-headerNotbar">
      <span class="close-btn" [ngClass]="{
        'close-btn-success': ['initial_cuttoff_time', 'cuttoff_time_x_min_left', 'cuttoff_time_delvery_date_pushed_further'].includes(step),
        'close-btn-warning': step === 'delivery_available',
        'close-btn-danger': step === 'collico_not_available'
      }" (click)="closeNotificationBar()">
        <span class="is-icon icon-cancel-circle"></span>
      </span>
      <ng-container>
        <ng-container>
          <span *ngIf="generalService.translationMode === true">
            (NOT001)
          </span>
          <div>{{stepMsg}}
            <span *ngIf="step == 'cuttoff_time_x_min_left'">
              {{ "NOTIFICATIONBAR.NOT001A.value" | translate }} {{timerDisplay}} {{ "GLOBAL.G050.value" | translate }}.
            </span>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>