import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {Command} from "./command";

@Injectable({
  providedIn: "root",
})
export class CommandEmitterService {
  private unsubscribe$ = new Subject<void>();
  private _commands: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
  ) {
  }

  createCommand(type: string, value: any): Command {
    return new Command(type, value);
  }

  emitCommand(command: Command) {
    this.commands.next(command);
  }

  enableCommandReceivement(callback: (message: Command) => void) {
    this.commands
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (!value) {
          return;
        }
        callback(value);
      });
  }

  get commands(): BehaviorSubject<any> {
    return this._commands;
  }

  set commands(value: BehaviorSubject<any>) {
    this._commands = value;
  }
}
