import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { isEmpty } from "lodash";
import { BehaviorSubject, Observable } from "rxjs";

import mockdata from "src/app/mockdata";
import { CacheService } from "./cache.service";
import { IPreference, ITempProduct } from "../ITypes";
import { ShopService, UserService } from "src/app/commons/services";
import { IProduct } from "src/app/commons/product-card/IProduct";
import { CentralStorageService } from "./central-storage.service";

interface ICart {
  id: number;
  products: IProduct[];
  total: string;
}

interface ICartResult {
  id: number;
  user_uuid: string;
  updated_at: string;
  created_at: string;
  total_price: number;
  items: Array<object>;
}

@Injectable({
  providedIn: "root",
})
export class CartService {
  cartData = {
    id: 0,
    products: [],
    total: 0,
  };

  cartDataObs$ = new BehaviorSubject(this.cartData);

  // defaults
  private productsTotal: BehaviorSubject<number> = new BehaviorSubject(0);
  public productQuantityChanges: BehaviorSubject<number> = new BehaviorSubject(
    0
  );
  // public productQuantityChanges: number = 0;
  private tempProductsTotal: BehaviorSubject<ITempProduct> =
    new BehaviorSubject(null);

  /**
   * Creates an instance of CartService.
   * @param {CacheService} storageService
   * @param {ShopService} shopService
   * @param {UserService} userService
   * @memberof CartService
   */
  constructor(
    private storageService: CacheService,
    private shopService: ShopService,
    private userService: UserService,
    private centralStorageService: CentralStorageService
  ) {
    this.getServerCartData();
  }

  async getServerCartData() {
    const cart = this.storageService.get("cart");
    if (cart && cart.products) {
      const serverCartData = await this.shopService.getCartFromServer(cart.id, {
        user_uuid: this.userService.getUserUUID,
      });

      if (serverCartData) {
        const { id, products } = serverCartData;
        this.cartData = {
          id,
          products,
          total: 0,
        };

        this.cartData.total = this.getCartProductsTotal();
        this.cartDataObs$.next(this.cartData);
        return this.cartData;
      }
    }
  }

  /**
   * this is to get cart data from storage
   * @readonly
   * @memberof CartService
   */
  get cart() {
    return this.storageService.get("cart");
  }

  /**
   * this is to get preference data from storage
   * @readonly
   * @memberof CartService
   */
  get preference() {
    return this.storageService.get("preference");
  }

  /**
   *this is to observe the change
   * in preference data from storage
   * @readonly
   * @memberof CartService
   */
  get getPreference() {
    return this.storageService.observeOn("preference");
  }

  /**
   * this is to set
   * preference data
   * @memberof CartService
   */
  set setPreference(data: IPreference) {
    this.storageService.set("preference", data);
  }

  get observeProductsTotal() {
    return this.productsTotal.asObservable();
  }

  get observeProductQuantityChanges() {
    return this.productQuantityChanges.asObservable();
  }

  set setProductQuantityChanges(value) {
    this.productQuantityChanges.next(value);
  }

  get getProductQuantityChanges() {
    return this.productQuantityChanges.getValue();
  }

  get getProductsTotal() {
    return this.productsTotal.getValue();
  }

  // temp cart product getter setter
  set setTempProductsTotal(value) {
    this.tempProductsTotal.next(value);
  }

  get observeTempProductsTotal() {
    return this.tempProductsTotal.asObservable();
  }

  get getTempProductsTotal() {
    return this.tempProductsTotal.getValue();
  }

  /**
   * this is to add or less
   * product in cart
   * @param {string} id
   * @param {number} price
   * @param {number} [quantity]
   * @memberof CartService
   */
  async addToCart(product, quantity: number) {
    const { C000: id, F004: price } = product;
    let cartId = await this.getOrCreateCartId();
    let _product = {
      ...product,
      id,
      price,
      quantity: quantity || 1,
    };
    let req;

    const existProductIndex = this.cartData.products.findIndex(
      (p) => p.id === id
    );
    const existProduct = this.cartData.products[existProductIndex];

    if (!isEmpty(existProduct)) {
      const _quantity = quantity || existProduct.quantity + 1;
      this.cartData.products[existProductIndex].quantity = _quantity;
      req = await this.updateTempCartItem(cartId, id, _quantity);
    } else {
      this.cartData.products.push({ ..._product });
      req = await this.addTempCartItem(cartId, id, _product.quantity);
    }

    this.cartData.total = this.getCartProductsTotal();
    this.cartDataObs$.next({ ...this.cartData });
    this.addToStorage();

    return req;
  }

  /**
   * this is to remove
   * product from cart
   * @param {string} id
   * @memberof CartService
   */
  async removeProductFromCart(id: string) {
    try {
      let updatedProducts = this.cartData.products.filter(
        (prod) => prod.id !== id
      );
      this.cartData.products = updatedProducts;
      this.cartData.total = this.getCartProductsTotal();
      this.cartDataObs$.next({ ...this.cartData });

      await this.deleteTempCartItem(this.cartData.id, id);
      this.addToStorage();
    } catch (error) {
      throw error;
    }
  }

  /**
   * this is to remove
   * cart
   * @memberof CartService
   */
  async removeCart() {
    this.cartData = {
      id: 0,
      products: [],
      total: 0,
    };
    const cart = await this.storageService.get("cart");
    if (cart) {
      this.cartDataObs$.next({ ...this.cartData });
      this.storageService.remove("cart");

      if (cart.id) {
        this.deleteCartFromServer(cart.id);
      }
    }
  }

  /**
   * this is to get product quantity by id
   * saved in storage
   * @param {string} id
   * @returns
   * @memberof CartService
   */
  productCount(id: string) {
    const product = this.cartData.products.find((p) => p.id === id);
    if (isEmpty(product)) {
      return 0;
    }
    return product.quantity;
  }

  private async addTempCartItem(
    cartId: number,
    productId: string,
    quantity: number
  ) {
    try {
      const { zip_code, delivery_date, delivery_method } =
        await this.centralStorageService.getKeysForPurchaseBtn();
      const vendorCode = this.centralStorageService.getVendorCode();
      await this.shopService.addCartItem(cartId, {
        user_uuid: this.userService.getUserUUID,
        product_id: productId,
        price: 0,
        quantity,
        zip_code,
        delivery_date,
        delivery_method,
        vendor_tag: vendorCode,
      });
    } catch (error) {
      throw error;
    }
  }

  private async updateTempCartItem(
    cartId: number,
    productId: string,
    quantity: number
  ) {
    const { zip_code, delivery_date, delivery_method } =
      await this.centralStorageService.getKeysForPurchaseBtn();
    const vendorCode = this.centralStorageService.getVendorCode();
    return await this.shopService.updateCartItem(cartId, productId, {
      user_uuid: this.userService.getUserUUID,
      quantity,
      zip_code,
      delivery_date,
      delivery_method,
      vendor_tag: vendorCode,
    });
  }

  private async deleteTempCartItem(cartId: number, productId: string) {
    try {
      await this.shopService.deleteCartItem(cartId, productId, {
        user_uuid: this.userService.getUserUUID,
      });
    } catch (error) {
      throw error;
    }
  }

  private async deleteCartFromServer(cartId: number) {
    try {
      await this.shopService.deleteCart(cartId, {
        user_uuid: this.userService.getUserUUID,
      });
    } catch (error) {
      throw error;
    }
  }

  private getCartProductsTotal() {
    return this.cartData.products.reduce(
      (a: number, b) => a + Number(b.price) * Number(b.quantity),
      0
    );
  }

  private async getOrCreateCartId(): Promise<number> {
    const cartExist = this.storageService.get("cart");
    if (cartExist) return cartExist.id;

    const { id } = await this.shopService.createCart({
      user_uuid: this.userService.getUserUUID,
    });

    this.cartData = {
      id: id,
      products: [],
      total: 0,
    };
    this.cartDataObs$.next(this.cartData);
    this.storageService.set("cart", this.cartData);

    return id;
  }

  private addToStorage() {
    const cartExist = this.storageService.get("cart");
    const products = this.cartData.products.map(({ id, price, quantity }) => {
      return {
        id,
        price,
        quantity,
      };
    });
    const cart = {
      id: cartExist.id,
      products,
    };
    this.storageService.set("cart", cart);
  }
}
