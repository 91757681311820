import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Subject } from "rxjs";
import { ApiService, SiteConfigService } from "src/app/core/services";
import { GeneralService } from "../services";
import { IConfig } from "src/app/core/ITypes";

@Component({
  selector: "sv-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
  footerConfig: IConfig["footer"];
  internalLinkedPages: boolean = false;
  footerContent: any = null;
  loading: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private siteConfigService: SiteConfigService,
    public generalService: GeneralService,
    private sanitizer: DomSanitizer,
    private apiService: ApiService
  ) {}

  async ngOnInit() {
    this.footerConfig = this.siteConfigService.siteConfig.footer;
    try {
      const { status_code, result } = await this.apiService
        .get(this.footerConfig.contentURL)
        .toPromise();

      if (status_code && status_code === 200 && result) {
        this.footerContent = this.sanitizer.bypassSecurityTrustHtml(
          result[0].content
        );
      }
    } catch (error) {
      this.loading = false;
      throw error;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
