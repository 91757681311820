import { Injectable } from "@angular/core";

import { isEmpty, reduce } from "lodash";
import { ToastrService } from "ngx-toastr";
import { auth as Auth } from "firebase/app";
import { BehaviorSubject, Observable } from "rxjs";
import { AngularFireAuth } from "@angular/fire/auth";

import { ApiService } from "./api.service";
import { AnalyticsService } from "./analytics.service";
import { UserService, ShopService } from "src/app/commons/services";
import { IUser, IUserLoginCredentails, IUserProfileRequest } from "../ITypes";
import { CacheService } from "./cache.service";
import { MyCookieService } from "./cookie.service";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CartService } from "./cart.service";
import { CentralStorageService } from "./central-storage.service";
import {
  debounceTime,
  distinctUntilChanged,
  shareReplay,
} from "rxjs/operators";
// import { CartService } from "./cart.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  // defaults
  public isUserLoggedIn: boolean = false;
  public idToken: string = null;
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private loggedInUser: BehaviorSubject<IUser> = new BehaviorSubject(null);
  private cachedUserResponse: Promise<any>;

  customerData: any = {
    email: "",
    phone: "",
    firstname: "",
    lastname: "",
    street: "",
    city: "",
    zip: "",
    country: "",
  };
  translations: any = null;
  authObj: IUserLoginCredentails = {
    name: "",
    lastName: "",
    pass: "",
    email: "",
    location: "",
    phone: "",
    birthDate: "",
  };

  /**
   * Creates an instance of AuthService.
   * @param {AngularFireAuth} firebaseAuth
   * @param {AnalyticsService} analyticsService
   * @param {UserService} userService
   * @param {ApiService} api
   * @memberof AuthService
   */
  constructor(
    private firebaseAuth: AngularFireAuth,
    private analyticsService: AnalyticsService,
    private cacheService: CacheService,
    private userService: UserService,
    private api: ApiService,
    private toastr: ToastrService,
    private cookieService: MyCookieService,
    private shopService: ShopService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private cartService: CartService,
    private centralStorageService: CentralStorageService
  ) {
    this.getFirebaseIDToken().subscribe(async (idToken) => {
      if (idToken) {
        this.idToken = idToken;
        await this.getJWT();
        //this.setUser();
      }
    });
    let browserLang = "fi";

    const tObs = translate.getTranslation(browserLang);
    tObs.subscribe((translation) => {
      this.translations = translation;
    });
  }

  /**
   *
   *Sets current user info
   * @memberof AuthService
   */
  async setUser() {
    this.firebaseAuth.authState.subscribe((user) => {
      if (!isEmpty(user) && user.email) {
        let {
          refreshToken,
          uid,
          displayName,
          photoURL,
          email,
          emailVerified,
          phoneNumber,
          isAnonymous,
          tenantId,
        } = user;

        this.loggedInUser.next({
          refreshToken,
          uid,
          displayName,
          photoURL,
          email: email || this.customerData.email,
          emailVerified,
          phoneNumber,
          isAnonymous,
          tenantId,
          firstname: this.customerData.firstname,
          lastname: this.customerData.lastname,
          phone: this.customerData.phone,
          street: this.customerData.street,
          city: this.customerData.city,
          country: this.customerData.country,
          zip: this.customerData.zip,
          company_name: this.customerData.company_name,
          company_reg_number: this.customerData.company_reg_number,
          birth_date: this.customerData.birth_date,
          fbUser: user,
        });

        this.analyticsService.setUser(email);

        this.loggedIn.next(true);
        this.isUserLoggedIn = true;
        return;
      } else {
        this.analyticsService.setUser();
        this.loggedInUser.next(null);
        this.loggedIn.next(false);
        this.isUserLoggedIn = false;
      }
    });
  }

  /**
   * resturns the logged in user's data.
   * @readonly
   * @type {Observable<IUser>}
   * @memberof AuthService
   */
  get observeUser(): Observable<IUser> {
    return this.loggedInUser.asObservable();
  }

  /**
   * returns if user is loggedin
   * @readonly
   * @type {Observable<boolean>}
   * @memberof AuthService
   */
  get isLoggedIn(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  setLoggedInUserState(data) {
    const authUser = this.loggedInUser.value;
    this.loggedInUser.next({
      ...authUser,
      ...data,
    });
  }

  /**
   *Fires fireBase authentication with email and password, also updates displayName
   *
   * @param {IUserLoginCredentails} data
   * @returns
   * @memberof AuthService
   */
  async signUp(data: IUserLoginCredentails) {
    try {
      const { auth } = this.firebaseAuth;
      let response = await auth.createUserWithEmailAndPassword(
        data.email,
        data.pass
      );
      response.user.updateProfile({
        displayName: data.name + " " + data.lastName,
      });
      this.setUser();
      return response;
    } catch (error) {
      return error;
    }
  }

  /**
   *
   *Logs in user with email and password.
   * @param {IUserLoginCredentails} data
   * @returns
   * @memberof AuthService
   */
  async logIn(data: IUserLoginCredentails) {
    try {
      const { auth } = this.firebaseAuth;
      let response = await auth.signInWithEmailAndPassword(
        data.email,
        data.pass
      );
      if (response.user && response.user.email) {
        this.analyticsService.setUser(response.user.email);
      }
      return response;
    } catch (error) {
      return error;
    }
  }

  /**
   *
   * Google authentication for user using firebase.
   * @returns
   * @memberof AuthService
   */
  async googleLogin() {
    try {
      const provider = new Auth.GoogleAuthProvider();
      const { auth } = this.firebaseAuth;

      let response = await auth.signInWithPopup(provider);
      if (response.user && response.user.email) {
        this.analyticsService.setUser(response.user.email);
      }
      return response;
    } catch (error) {
      return error;
    }
  }

  /**
   *
   *Facebook authentication for user using firebase.
   * @returns
   * @memberof AuthService
   */
  async facebookLogin() {
    try {
      const provider = new Auth.FacebookAuthProvider();
      const { auth } = this.firebaseAuth;

      let response = await auth.signInWithPopup(provider);
      if (response.user && response.user.email) {
        this.analyticsService.setUser(response.user.email);
      }
      return response;
    } catch (error) {
      return error;
    }
  }

  /**
   *
   *SignOut current user.
   * @memberof AuthService
   */
  async signOut() {
    this.cacheService.remove("token", "session");
    return await this.firebaseAuth.auth.signOut();
  }

  async loadUserDetails(): Promise<any> {
    if (this.cachedUserResponse) {
      return this.cachedUserResponse;
    }

    const payload = {
      id_token: await this.idToken,
      user_uuid: await this.userService.getUserUUID,
    };

    this.cachedUserResponse = this.api
      .post(payload, "/user/firebase")
      //.pipe(debounceTime(500))
      .toPromise();

    return this.cachedUserResponse;
  }

  getFirebaseIDToken(): Observable<string> {
    return this.firebaseAuth.idToken.pipe(
      distinctUntilChanged(),
      shareReplay(1)
    );
  }

  /**
   * represents getting user
   * auth token
   * @private
   * @param {string} id_token
   * @memberof AuthService
   */
  async getJWT() {
    try {
      const { status_code, result } = await this.loadUserDetails();

      if (status_code === 200 && result) {
        this.cacheService.set("token", `Bearer ${result.token}`, "session");
        this.userService.getWallet(); // get wallet for logged in user
        this.userService.setUserUUID = result.user_uuid;

        // here we update local cart to logged in user cart ....
        let { cart } = this.cartService;

        if (cart && cart.id && result.user_uuid) {
          await this.shopService
            .updateCart(
              cart.id,
              {
                user_uuid: result.user_uuid,
              },
              {
                user_uuid: result.user_uuid,
              }
            )
            .then(async () => {
              if (
                (!this.route.snapshot.queryParams["SETTLED"] ||
                  this.route.snapshot.queryParams["SETTLED"] !== "1") &&
                !this.route.snapshot.queryParams["order-token"]
              ) {
                await this.shopService.getCart(cart.id, {
                  user_uuid: result.user_uuid,
                });
              }
            });
        }

        this.customerData = result;
        await this.userService.getShippingAddresses();

        //Todo: Move this to somewhere else
        if (this.authObj.name) {
          const userProfile: any = {
            firstname: this.authObj.name,
            lastname: this.authObj.lastName,
            email: this.authObj.email,
            phone: this.authObj.phone,
            user_uuid: this.userService.getUserUUID,
            birth_date: this.authObj.birthDate,
          };
          this.userService.updateProfile(userProfile);
        }
        await this.setUser();

        // await this.userService.getLastOrder();
        // uncomment this, or handle via config as this is for pakastamua only..
        // uncomment this, or handle via config as this is for pakastamua only..
        // uncomment this, or handle via config as this is for pakastamua only..
      }
    } catch (error) {
      console.log("error in getJWT: ", error);
    }
  }

  /**
   * represents get
   * refresh token
   * @memberof AuthService
   */
  getRefreshToken = () => {
    try {
      return new Observable((observer) => {
        this.api
          .getResponseHeaders("/user/refresh")
          .subscribe(async ({ status_code, headers }) => {
            if (status_code === 204 && headers) {
              const token = await headers.get("Authorization");
              await this.cacheService.set("token", token, "session");
              observer.next(headers.get("Authorization"));
            }
          });
      });
    } catch (error) {
      console.log("error in getRefreshToken: ", error.message);
    }
  };

  async forgotPassword(email: string, type?: string) {
    try {
      const { status_code, result } = await this.api
        .post({ email, type }, "/user/reset-password-request")
        .toPromise();
      //await this.firebaseAuth.auth.sendPasswordResetEmail(email);

      if (result) {
        this.toastr.success(result);
      }
      return true;
    } catch (error) {
      this.toastr.error(error.message);
      return false;
    }
  }

  async resetPassword(newPassword: string, oobCode: string) {
    try {
      await this.firebaseAuth.auth.confirmPasswordReset(oobCode, newPassword);
      this.toastr.success(this.translate.instant("AUTH.A033.value"));
      return true;
    } catch (error) {
      this.toastr.error(error.message);
      return false;
    }
  }

  async resetPasswordVerification(oobCode: string) {
    try {
      const response = await this.firebaseAuth.auth.verifyPasswordResetCode(
        oobCode
      );
      return true;
    } catch (error) {
      if (error && error.code === "auth/invalid-action-code") {
        this.toastr.error(this.translations.AUTH.A027.value);
      }
      return false;
    }
  }

  async updateDisplayName(displayName: string) {
    try {
      const authUser = this.loggedInUser.value;
      const userRes = await authUser.fbUser.updateProfile({
        displayName,
      });
    } catch (error) {
      if (error.message) {
        this.toastr.error(error.message, "Update Error");
      }
      throw error;
    }
  }
}
