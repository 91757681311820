import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";

import { ToastrModule } from "ngx-toastr";
import { NgxWebstorageModule } from "ngx-webstorage";
import { CookieService } from "ngx-cookie-service";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireAnalyticsModule } from "@angular/fire/analytics";

import { RoutingModule } from "src/app/core/routing/routing.module";
import { CommonsModule } from "src/app/commons/commons.module";

import { ApiService } from "src/app/core/services/api.service";
import { CacheService } from "src/app/core/services/cache.service";
import { GeneralService, ProductService } from "src/app/commons/services";
import { GlobalEventService, SiteConfigService } from "src/app/core/services";
import { MyTranslationsLoader } from "src/app/my-translations-loader.service";
import { InterceptorService } from "./services/interceptor.service";

import { AppComponent } from "./app.component";
import { environment } from "src/app/tenants/olutmaailma/environments/environment";

const HttpLoaderFactory = (httpClient: HttpClient) => {
  // return new TranslateHttpLoader(httpClient, "http://localhost:3000/", "");
  return new TranslateHttpLoader(httpClient);
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    HttpClientModule,
    RoutingModule,
    CommonsModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    NgxWebstorageModule.forRoot({ prefix: "sv", separator: "-" }),
    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
      closeButton: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // useFactory: HttpLoaderFactory,
        useClass: MyTranslationsLoader,
        deps: [HttpClient, GeneralService],
      },
      // isolate: true
    }),
    FormsModule,
  ],
  providers: [
    SiteConfigService,
    MyTranslationsLoader,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    ApiService,
    CookieService,
    CacheService,
    GeneralService,
    ProductService,
    GlobalEventService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
