export default [
  {
    summary: {
      default: {
        C000: "1",
        C001: "TEST000001",
        C002: "PACK",
        D001: "1000002",
        D002: "Y",
        F000:
          "https://s3.eu-north-1.amazonaws.com/testing.storeverse.io/TEST000001.jpg",
        F001: "Valiojogurtti",
        F002: "HYLA strawberry yoghurt",
        F003: "",
        F004: "0.75",
        F005: "200g",
        F006: "3.75€ / 1kg",
        F007: "",
        F008: "",
        F009: "",
        F010: "Buy",
        F011: "ACTIVE"
      }
    }
  },
  {
    summary: {
      default: {
        C000: "2",
        C001: "TEST000002",
        C002: "PACK",
        D001: "1000002",
        D002: "Y",
        F000:
          "https://s3.eu-north-1.amazonaws.com/testing.storeverse.io/TEST000002.jpg",
        F001: "Valiojogurtti",
        F002: "HYLA blueberry yoghurt",
        F003: "",
        F004: "0.75",
        F005: "200g",
        F006: "3.75€ / 1kg",
        F007: "",
        F008: "",
        F009: "",
        F010: "Buy",
        F011: "ACTIVE"
      }
    }
  },
  {
    summary: {
      default: {
        C000: "3",
        C001: "TEST000003",
        C002: "PACK",
        D001: "1000002",
        D002: "Y",
        F000:
          "https://s3.eu-north-1.amazonaws.com/testing.storeverse.io/TEST000003.jpg",
        F001: "Valiojogurtti",
        F002: "HYLA banana yoghurt",
        F003: "",
        F004: "0.75",
        F005: "200g",
        F006: "3.75€ / 1kg",
        F007: "",
        F008: "",
        F009: "",
        F010: "Buy",
        F011: "ACTIVE"
      }
    }
  },
  {
    summary: {
      default: {
        C000: "4",
        C001: "TEST000004",
        C002: "PACK",
        D001: "1000002",
        D002: "Y",
        F000:
          "https://s3.eu-north-1.amazonaws.com/testing.storeverse.io/TEST000004.jpg",
        F001: "Danone Activia",
        F002: "Strawberry yoghurt",
        F003: "",
        F004: "2.29",
        F005: "4 x 125g",
        F006: "4.58€ / 1kg",
        F007: "",
        F008: "",
        F009: "",
        F010: "Buy",
        F011: "ACTIVE"
      }
    }
  },
  {
    summary: {
      default: {
        C000: "5",
        C001: "TEST000005",
        C002: "PACK",
        D001: "1000002",
        D002: "Y",
        F000:
          "https://s3.eu-north-1.amazonaws.com/testing.storeverse.io/TEST000005.jpg",
        F001: "Danone Activia",
        F002: "Strawberry yoghurt, 6 x 4-pack",
        F003: "",
        F004: "12.99",
        F005: "24 x 125g",
        F006: "4.33€ / 1kg",
        F007: "",
        F008: "",
        F009: "",
        F010: "Buy",
        F011: "ACTIVE"
      }
    }
  },
  {
    summary: {
      default: {
        C000: "6",
        C001: "TEST000006",
        C002: "VOL",
        D001: "1010001",
        D002: "Y",
        F000:
          "https://s3.eu-north-1.amazonaws.com/testing.storeverse.io/TEST000006.jpg",
        F001: "Banana",
        F002: "",
        F003: "Mega price!",
        F004: "1.69",
        F005: "1kg",
        F006: "1.69€ / 1kg",
        F007: "2.29",
        F008: "-26%",
        F009: "0.60",
        F010: "Buy",
        F011: "ACTIVE"
      }
    }
  },
  {
    summary: {
      default: {
        C000: "7",
        C001: "TEST000007",
        C002: "UNIT",
        D001: "1040001",
        D002: "Y",
        F000:
          "https://s3.eu-north-1.amazonaws.com/testing.storeverse.io/TEST000007.jpg",
        F001: "Whole salmon",
        F002: "",
        F003: "",
        F004: "28.50",
        F005: "3kg",
        F006: "9.50€ / 1kg",
        F007: "",
        F008: "",
        F009: "",
        F010: "Buy",
        F011: "ACTIVE"
      }
    }
  }
];

export const productsTree = [
  {
    label: "Fruits and veggies",
    visible: false,
    items: [
      {
        label: "Salads",
        url: ""
      },
      {
        label: "Tomatoes",
        url: ""
      },
      {
        label: "Potatoes",
        url: ""
      },
      {
        label: "Onions",
        url: ""
      },
      {
        label: "Root vegetables",
        url: ""
      },
      {
        label: "Other vegetables",
        url: ""
      },
      {
        label: "Herbs",
        url: ""
      },
      {
        label: "Local fruit",
        url: ""
      },
      {
        label: "Citruses",
        url: ""
      },
      {
        label: "Exotic fruit",
        url: ""
      },
      {
        label: "Berries",
        url: ""
      },
      {
        label: "Mushrooms",
        url: ""
      },
      {
        label: "Frozen fruit and veggies",
        url: ""
      }
    ]
  },
  {
    label: "Milk products",
    visible: false,
    items: []
  },
  {
    label: "Cheese",
    visible: false,
    items: []
  },
  {
    label: "Yoghurt & desserts",
    visible: false,
    items: []
  },
  {
    label: "Meat",
    visible: false,
    items: []
  },
  {
    label: "Poultry & eggs",
    visible: false,
    items: []
  },
  {
    label: "Fish & shellfish",
    visible: false,
    items: []
  },
  {
    label: "Plant-based protein",
    visible: false,
    items: []
  },
  {
    label: "Bakery products",
    visible: false,
    items: []
  },
  {
    label: "Breakfast",
    visible: false,
    items: []
  },
  {
    label: "Spices and sauces",
    visible: false,
    items: []
  },
  {
    label: "Cooking",
    visible: false,
    items: []
  },
  {
    label: "Frozen food",
    visible: false,
    items: []
  },
  {
    label: "Ready-made food",
    visible: false,
    items: []
  },
  {
    label: "Drinks Sweets & snacks",
    visible: false,
    items: []
  },
  {
    label: "Baby products",
    visible: false,
    items: []
  },
  {
    label: "Pet products",
    visible: false,
    items: []
  },
  {
    label: "Paper products",
    visible: false,
    items: []
  },
  {
    label: "Personal care",
    visible: false,
    items: []
  },
  {
    label: "Housekeeping",
    visible: false,
    items: []
  },
  {
    label: "Home and cooking",
    visible: false,
    items: []
  },
  {
    label: "Other products",
    visible: false,
    items: []
  }
];

export const states = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District Of Columbia",
  "Federated States Of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Islands",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];

// ------------ Recipes dummy data begins ---------------------

const dummyContent = `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo
consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate
velit esse molestie consequat, vel illum dolore eu feugiat nulla
facilisis at vero eros et accumsan et iusto odio dignissim qui
blandit praesent luptatum zzril delenit augue duis dolore te feugait
nulla facilisi.`;

const recipeExtras = [
  {
    id: "123a",
    title: "Herby spring chicken pot pie",
    content: dummyContent,
    isCollapsed: true
  },
  {
    id: "123b",
    title: "One-pan salmon with roast asparagus",
    content: dummyContent,
    isCollapsed: true
  },
  {
    id: "123c",
    title: "Pea, mint & spring onion soup with parmesan biscuits",
    content: dummyContent,
    isCollapsed: true
  },
  {
    id: "123d",
    title: "Rhubarb & gingernut cheesecake",
    content: dummyContent,
    isCollapsed: true
  },
  {
    id: "123f",
    title: "Green spinach & lemon risotto with crispy prosciutto",
    content: dummyContent,
    isCollapsed: true
  },
  {
    id: "123e",
    title: "Spring tarts with watercress salsa verde & whipped lemon feta",
    content: dummyContent,
    isCollapsed: true
  }
];

export const recipes = [
  {
    id: "1",
    recipeName: "Herby spring chicken pot pie",
    dateAdded: "23.02.2020",
    imgSource: "./assets/images/article/1.jpg",
    extra: recipeExtras
  },
  {
    id: "2",
    recipeName: "One-pan salmon with roast asparagus",
    dateAdded: "23.02.2020",
    imgSource: "./assets/images/article/2.jpg",
    extra: recipeExtras
  },
  {
    id: "3",
    recipeName: "Pea, mint & spring onion soup with parmesan biscuits",
    dateAdded: "23.02.2020",
    imgSource: "./assets/images/article/3.jpg",
    extra: recipeExtras
  },
  {
    id: "4",
    recipeName: "Rhubarb & gingernut cheesecake",
    dateAdded: "23.02.2020",
    imgSource: "./assets/images/article/2.jpg",
    extra: recipeExtras
  },
  {
    id: "5",
    recipeName: "Green spinach & lemon risotto with crispy prosciutto",
    dateAdded: "23.02.2020",
    imgSource: "./assets/images/article/3.jpg",
    extra: recipeExtras
  },
  {
    id: "6",
    recipeName: "Spring tarts with watercress salsa verde & whipped lemon feta",
    dateAdded: "23.02.2020",
    imgSource: "./assets/images/article/1.jpg",
    extra: recipeExtras
  }
];

// ------------ Recipes dummy data ends ---------------------
