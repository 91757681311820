<div class="loader">
  <div class="content" *ngFor="let n of nums; index as i">
    <content-loader width="100" height="20">
      <svg:rect x="0" y="0" rx="0" ry="0" width="20" height="20" />
      <svg:rect x="22" y="0" rx="0" ry="0" width="35" height="4" />
      <svg:rect x="22" y="6" rx="0" ry="0" width="40" height="4" />
      <svg:rect x="22" y="12" rx="0" ry="0" width="10" height="4" />
      <svg:rect x="68" y="2" rx="0" ry="0" width="10" height="16" />
      <svg:rect x="85" y="7" rx="0" ry="0" width="10" height="4" />
    </content-loader>
  </div>
</div>
