import {
  Directive,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";

import { Subject, Subscription } from "rxjs";
import { throttleTime } from "rxjs/operators";

@Directive({
  selector: "[svThrottleClick]",
})
export class ThrottleClickDirective implements OnInit, OnDestroy {
  @Input()
  throttleTime = 250;

  @Output()
  throttleClick = new EventEmitter();

  private clicks = new Subject();
  private subscription: Subscription;

  constructor() {}

  ngOnInit() {
    this.subscription = this.clicks
      .pipe(throttleTime(this.throttleTime))
      .subscribe((e) => this.throttleClick.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener("click", ["$event"])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
