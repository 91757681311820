<!-- navPanel -->
<div class="l-page">
  <div class="c-navPanel" [style.top]="navbarHeight">
    <div class="c-navPanel__header">
      <button class="b-navPanel--close js-toggle-navPanel" (click)="handleCloseProductTree()">
        <span class="b-navPanel--close__icon icon-close" aria-hidden="true"></span>
        <span class="b-navPanel--close__title visuallyhidden">Close</span>
      </button>
    </div>

    <div class="c-navPanel__body">

      <!-- Display vendors if not selected any -->
      <ng-container *ngIf="displayType === EDisplayType.Vendors">
        <div class="vendors-container">
          <ul class="c-navPanel__menu" *ngIf="vendors && vendors.length">
            <li class="c-navPanel__menu-item" *ngFor="let vendor of vendors">
              <a (click)="selectVendorTag(vendor)" class="c-navPanel__menu-link">
                {{vendor.name}}
              </a>
            </li>
          </ul>
        </div>
      </ng-container>

      <!-- Display vendor if selected any -->
      <ng-container *ngIf="displayType === EDisplayType.Display_Groups">
        <div class="vendor-container" *ngIf="vendors.length && !vendor">
          <a href="javascript:void(0)" (click)="changeDisplayType()" class="choose-vendor">
            <span *ngIf="generalService.translationMode === true">
              (SI001)
            </span>
            {{ "SIDEBAR.SI001.value" | translate }}
          </a>

          <hr />
        </div>

        <div class="vendor-container" *ngIf="vendor">
          Shop <span class="vendor-name">{{ vendor.name }}</span>

          <span class="remove-tag-btn-container">
            <a href="javascript:void(0)" (click)="removeVendorTag()" class="icon-remove-small remove-tag-btn">&nbsp;</a>
          </span>

          <hr />
        </div>


        <!-- Display display groups -->
        <ul class="c-navPanel__menu" *ngIf="displayGroups && displayGroups.length">
          <li class="c-navPanel__menu-item" *ngFor="let group of displayGroups" [ngClass]="{
            'c-navPanel__menu-item--selected':
              this.selectedParentGroup === group.code
          }">
            <a routerLink="/p/search" [queryParams]="{
              group: group.code,
              mode: generalService.debugMode ? 'debug' : null,
              filters: filtersParams
            }" (click)="handleCollapse(group)" class="c-navPanel__menu-link"
              [ngClass]="{ 'buy-btn-color': group.has_vendor }">{{ group.name }}
              <!-- <span
              *ngIf="group.has_vendor"
              class="is-icon icon-left-tail c-navPanel__menu-item--vendor"
              >&nbsp;</span> -->
            </a>

            <ul *ngIf="
              group.display_groups &&
              group.display_groups.length &&
              (group.expanded === true ||
                (this.route.snapshot.queryParams.group &&
                  this.route.snapshot.queryParams.group === group.code))
            " class="c-navPanel__subMenu">
              <li *ngFor="let subGroup of group.display_groups" class="c-navPanel__menu-subItem" [ngClass]="{
                'c-navPanel__menu-subItem--selected':
                  this.selectedChildGroup === subGroup.code
              }">
                <a routerLink="/p/search" [queryParams]="{
                  group: group.code,
                  subGroup: subGroup.code,
                  mode: generalService.debugMode ? 'debug' : null,
                  filters: filtersParams
                }" class="c-navPanel__menu-subLink" [ngClass]="{ 'buy-btn-color': subGroup.has_vendor }"
                  (click)="subGroupClicked()">
                  {{ subGroup.name }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>

  <div class="c-navPanelOverlay js-toggle-navPanel"></div>
</div>