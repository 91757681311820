import { Component, OnInit } from "@angular/core";

@Component({
  selector: "sv-product-card-loader",
  templateUrl: "./product-card-loader.component.html",
  styleUrls: ["./product-card-loader.component.scss"],
})
export class ProductCardLoaderComponent implements OnInit {
  // defaults
  nums = Array.from(Array(7).keys());

  constructor() {}

  ngOnInit(): void {}
}
