import { ToastrService } from "ngx-toastr";
import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  AfterViewInit,
} from "@angular/core";
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  NavigationStart,
} from "@angular/router";
import { DOCUMENT } from "@angular/common";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import {
  MyCookieService,
  AnalyticsService,
  CacheService,
  SeoService,
  AuthService,
  SiteConfigService,
  CentralStorageService,
} from "../core/services/";
import { GeneralService } from "../commons/services/general.service";
import { AngularFireAnalytics } from "@angular/fire/analytics";
import { DisplayGroupService, VendorService } from "../commons/services";
import { CollicoService } from "../commons/services/collico.service";
import { IDeliveryDate } from "./cart/ITypes";

@Component({
  selector: "sv-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit, OnDestroy {
  // defaults
  private unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private cookieService: MyCookieService,
    public generalService: GeneralService,
    private analyticsService: AnalyticsService,
    private activatedRoute: ActivatedRoute,
    private cacheService: CacheService,
    private afAnalytics: AngularFireAnalytics,
    public displayGroupService: DisplayGroupService,
    public seoService: SeoService,
    public siteConfigService: SiteConfigService,
    private toastr: ToastrService,
    public authService: AuthService,
    public centralStorageService: CentralStorageService,
    public collicoService: CollicoService,
    public vendorService: VendorService
  ) {
    const mode = this.activatedRoute.snapshot.queryParams["mode"];
    const oobCode = this.activatedRoute.snapshot.queryParams["oobCode"];

    if (mode === "resetPassword") {
      this.generalService.setCartPanelState = false;
      if (oobCode) {
        this.verifyResetPasswordLink(oobCode);
      }
    }

    if (mode === "debug") {
      this.generalService.activateDebugMode();
    }

    if (mode === "translation") {
      this.generalService.activateTranslationMode();
    }
  }

  async ngOnInit() {
    try {
      const vendorCode =
        this.activatedRoute.snapshot.queryParams["vendor"] || null;
      const preSelectedDeliveryDate =
        this.activatedRoute.snapshot.queryParams["delivery"] || null;

      let localUserID = this.cacheService.get("UUTID");
      let centralUserInfo = this.cacheService.get("user-shipping-info");
      let centralUserBillingInfo = this.cacheService.get("user-billing-info");
      let centralCheckoutInfo = this.cacheService.get("checkout-info");

      if (!localUserID) {
        let id = this.generalService.generateUUID;
        this.cacheService.set("UUTID", id);
        this.analyticsService.setUser(id);
      } else {
        this.analyticsService.setUser();
      }

      if (!centralUserInfo) {
        await this.centralStorageService.setUserShippingInfoDefault();
      }

      if (!centralCheckoutInfo) {
        await this.centralStorageService.setCheckoutInfoDefault();
      }

      if (!centralUserBillingInfo) {
        await this.centralStorageService.setUserBillingInfoDefault();
      }

      if (vendorCode) {
        this.centralStorageService.setVendorCode(vendorCode);
      }

      if (preSelectedDeliveryDate) {
        // this.centralStorageService.setCheckoutInfoCollicoDateByZipPopup(
        //   preSelectedDeliveryDate
        // );
        this.collicoService.preSelectedDeliveryDate = preSelectedDeliveryDate;
      }

      let mode = null;
      this.displayGroupService.getDisplayGroups();

      // listen router change
      this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((evt) => {
        const modeParam = this.activatedRoute.snapshot.queryParams["mode"];

        if (modeParam) {
          mode = modeParam;
        }

        if (evt instanceof NavigationStart) {
          let tenantName = this.siteConfigService.siteConfig.title;
          if (evt.url.includes("/p/")) {
            if (evt.url.includes("/p/search")) {
              this.seoService.setTitle = tenantName; // add search word here to change search page title
              let document = this.generalService.getDocument;

              this.analyticsService.screenView(
                document.location.origin + evt.url,
                "searchPage | " + tenantName
              );
            } else {
            }
          } else {
            this.seoService.setTitle = tenantName;
          }
        }

        if (
          evt instanceof NavigationEnd &&
          (mode === "debug" || mode === "translation")
        ) {
          this.router.navigateByUrl(evt.url + "?mode=" + mode);
          return;
        }

        if (!(evt instanceof NavigationEnd)) {
          return;
        }

        let document = this.generalService.getDocument;
        let pageTitle = document.title;
        let { href, pathname } = document.location;
        // let meta = this.analyticsService.makeMetaData(pathname);

        // here we can detect any route change through the app.
        // screen_view
        if (pathname === "/") {
          this.analyticsService.screenView(href, "homePage | " + pageTitle);
        }
      });
    } catch (error) {
      console.log("error: ", error);
    }

    // observe if user has information
    this.authService.observeUser
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (userData) => {
        if (userData) {
          await this.centralStorageService.setUserShippingInfoWhenLoggedIn({
            firstname: userData.firstname,
            lastname: userData.lastname,
            email: userData.email,
            phone: userData.phone,
          });

          const storedCheckoutInfo = this.cacheService.get("checkout-info");
          const storedUserShippingInfo = await this.cacheService.get(
            "user-shipping-info"
          );

          const { collico } = storedCheckoutInfo;
          const { user_info } = storedUserShippingInfo;

          if (user_info.zip_code) {
            let zipCodeFromStorage = user_info.zip_code;

            this.collicoService
              .getDeliveryAvailability({
                zip_code: zipCodeFromStorage,
              })
              .subscribe(async (deliveryAvailability) => {
                const {
                  collico_available,
                  delivery_available,
                  pickup_available,
                  valid_zipcode,
                } = deliveryAvailability;

                if (collico_available || delivery_available) {
                  this.collicoService.collicoAvailable = collico_available;
                  this.collicoService.deliveryAvailable = delivery_available;
                  //Todo: should be removed when refactor
                  if (pickup_available) {
                    this.collicoService.collicoAvailable = true;
                  }
                  if (valid_zipcode) {
                    zipCodeFromStorage = valid_zipcode;
                  }
                  this.collicoService.setCollicoZip(zipCodeFromStorage);

                  if (collico_available || pickup_available) {
                    this.collicoService
                      .getDeliveryDatesNew({
                        zip_code: zipCodeFromStorage,
                      })
                      .subscribe(async (deliveryDates) => {
                        const { delivery_dates } = deliveryDates;

                        let nearestCollicoDeliveryDateInfo =
                          this.collicoService.handlePreSelectedDeliveryDate(
                            this.collicoService.preSelectedDeliveryDate,
                            delivery_dates
                          );

                        if (!nearestCollicoDeliveryDateInfo) {
                          nearestCollicoDeliveryDateInfo =
                            delivery_dates.find(Boolean);

                          if (nearestCollicoDeliveryDateInfo) {
                            const { date: storedCollicoDate } = collico;
                            const { date: nearestCollicoDate } =
                              nearestCollicoDeliveryDateInfo;
                            const nearestCollicoDateParse =
                              Date.parse(nearestCollicoDate);
                            const storedCollicoDateParse =
                              Date.parse(storedCollicoDate);

                            if (
                              storedCollicoDateParse >= nearestCollicoDateParse
                            ) {
                              nearestCollicoDeliveryDateInfo =
                                await this.collicoService.getCollicoDeliveryDate(
                                  delivery_dates,
                                  storedCollicoDate
                                );
                            }
                          }
                        }

                        const { date, default_start, default_end, start, end } =
                          nearestCollicoDeliveryDateInfo;
                        const collicoObj = {
                          date,
                          timeFrom: default_start,
                          timeTo: default_end,
                          timeStart: start,
                          timeEnd: end,
                          zoneId: null,
                          zoneName: null,
                        };
                        this.centralStorageService.setCheckoutInfoByCheckoutPage(
                          "collico",
                          collicoObj
                        );

                        //Store to Service
                        this.collicoService.setCollicoDeliveryDateAndTime(
                          nearestCollicoDeliveryDateInfo
                        );

                        this.generalService.zipPopUpVisible = false;
                      });
                  }
                }
              });
          }
        }
      });

    this.vendorService.loadVendors().subscribe((vendors) => {
      this.vendorService.setVendors(vendors);

      const vendorCode = this.centralStorageService.getVendorCode();
      if (vendorCode) {
        const vendor = vendors.find((x) => x.code === vendorCode);
        if (vendor) {
          this.vendorService.setVendor(vendor);
        }
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async verifyResetPasswordLink(oobCode) {
    try {
      const isVerified = await this.authService.resetPasswordVerification(
        oobCode
      );

      if (isVerified) {
        this.generalService.setModalState = {
          id: "5",
          name: "resetPasswordModal",
          isOpen: true,
          oobCode,
        };
      }
    } catch (error) {}
  }

  // async ngAfterViewOnit() {
  //   let cookiesAllowed = await this.cookieService.get("cookie-consent");
  //   if (cookiesAllowed && cookiesAllowed === "false") {
  //     this.afAnalytics.setAnalyticsCollectionEnabled(false);
  //     this.cookieService.deleteAll();
  //     this.cookieService.set("cookie-consent", "false");
  //   }
  // }
}
