import { Component, OnInit } from "@angular/core";

@Component({
  selector: "sv-cart-panel-loader",
  templateUrl: "./cart-panel-loader.component.html",
  styleUrls: ["./cart-panel-loader.component.scss"],
})
export class CartPanelLoaderComponent implements OnInit {
  // defaults
  nums = Array.from(Array(2).keys());

  constructor() {}

  ngOnInit(): void {}
}
