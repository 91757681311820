<div class="c-headerToolsContainer" [style.top]="showNotificationBar ? '45px' : '0'">
  <div class="l-page">
    <div class="c-headerTools">
      <div class="c-headerTools-navPanelToggle">
        <button class="b-navPanelToggle" (click)="handleToggleProductTree()">
          <span class="b-navPanelToggle__icon icon-menu" aria-hidden="true"></span>
          <span class="b-navPanelToggle__title">
            <span *ngIf="generalService.translationMode === true">
              (S001)
            </span>
            {{ "SEARCH.S001.value" | translate }}
          </span>
        </button>
      </div>

      <!-- NEW SEARCH STARTS -->
      <div class="c-headerTools-searchContainer">
        <div class="c-header-search c-searchContainer" [ngClass]="searchFocused ? 'c-searchContainer--isFocus' : ''">
          <div class="c-search">
            <div class="c-search__form">
              <div class="c-search__selectedFilters">
                <button class="b-tag b-tag--hasClose remove-tag" *ngFor="let item of searchTags"
                  (click)="removeFromSearchTags(item)">
                  {{ hasSearchTags === true ? item.value : item.name }}
                </button>
              </div>
              <input type="search" name="" class="c-search__input search-ignore2" [placeholder]="
                  generalService.translationMode === true
                    ? '(S002) ' + ('SEARCH.S002.value' | translate)
                    : ('SEARCH.S002.value' | translate)
                " [(ngModel)]="searchWord" (focus)="searchBarFocusToggle()" (keyup)="searchBarOnChange($event)" />
              <div class="c-dropdown c-search__filters search-ignore" *ngIf="isSearchFiltersVisible">
                <button class="b-dropdown__toggle b-search__filters-toggle search-ignore" (click)="toggleDropDown()">
                  <span class="c-badge c-badge--info search-ignore" *ngIf="searchFiltersCount > 0">
                    {{ searchFiltersCount }}
                  </span>
                  <!-- Filters -->
                  <span *ngIf="generalService.translationMode === true">
                    (S003)
                  </span>
                  {{ "SEARCH.S003.value" | translate }}
                </button>
                <div class="c-dropdown__options search-ignore" [ngClass]="dropDownOptions ? 'c-dropdown--isOpen' : ''"
                  *ngIf="dropDownOptions">
                  <div *ngFor="let eachFilter of searchFilters; index as i" class="c-checkbox c-formItem search-ignore">
                    <input type="checkbox" id="headerSearchFilter{{ i }}" class="c-checkbox__input search-ignore"
                      [checked]="eachFilter.checked" (click)="addToSearchFilters(eachFilter)" />
                    <label class="c-checkbox__label search-ignore" for="headerSearchFilter{{ i }}">{{
                      eachFilter.label.fi }}</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="c-search__results search-ignore2" *ngIf="
                searchFocused &&
                previousSearches.length >= 1 &&
                isPreviousSearchVisible
              ">
              <ul class="c-search__resultsList search-ignore2">
                <li class="c-search__resultsList-item search-ignore2" *ngFor="let item of previousSearches">
                  <a href="javascript:void(0)" (click)="searchAgain(item)" class="search-ignore2">{{ item }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- NEW SEARCH ENDS -->

      <div class="c-headerTools-tools h-hide--mobile">
        <div class="c-dropdown zip-ignore">
          <button class="b-headerTools zip-ignore" [ngClass]="{
              'b-headerTools--delivery':
                storedUserShippingInfo && storedUserShippingInfo.zip_code
                  ? true
                  : false
            }" aria-label="Delivery time" (click)="toggleZip()">
            <span class="b-headerTools__iconContainer zip-ignore">
              <span class="b-headerTools__icon icon-delivery-truck zip-ignore" aria-hidden="true"></span>
            </span>
            <span class="b-headerTools__title zip-ignore">
              <span class="zip-ignore">
                {{
                collicoService.collicoZip
                ? collicoService.collicoZip
                : "Minne?"
                }}
              </span>

              <span class="zip-ignore">
                {{
                collicoService.collicoAvailable &&
                collicoService.nearestCollicoDeliveryDateAndTime
                ? "/ " +
                (collicoService.nearestCollicoDeliveryDateAndTime.date
                | date : "dd.MM")
                : ""
                }}
              </span>
            </span>
          </button>
        </div>

        <button (click)="handleToggleCartPanel()" [ngClass]="{
            'cart-btn': true,
            'b-headerTools': true,
            'b-headerTools--cart': true,
            'b-headerTools--cart-hasItems':
              cart.products.length && cart.products.length > 0 ? true : false
          }">
          <span class="b-headerTools__iconContainer">
            <span class="b-headerTools__icon icon-buy" aria-hidden="true"></span>
          </span>
          <span class="b-headerTools__title">
            <span *ngIf="
                cart &&
                  cart.products &&
                  cart.products.length &&
                  cart.products.length > 0;
                else noProducts
              " class="cart-total">{{ cartTotal.toFixed(2) | shopCurrency }}
            </span>
            <ng-template #noProducts>
              <span class="cart-total">{{ "0.00" | shopCurrency }} </span>
            </ng-template>

            <span class="cart-quantity">({{ cart.products.length }})</span>
          </span>
        </button>
      </div>
      <div #zipDropdownOptions id="zipDropdownOptions" class="c-dropdown__options zip-ignore" [ngClass]="{
          'c-dropdown--isOpen': generalService.zipPopUpVisible,
          visuallyhidden: !generalService.zipPopUpVisible
        }">
        <div class="c-header__deliveryDropdown zip-ignore">
          <div class="c-userBubble zip-ignore">

            <!-- ^ Zip code different text based on conditions -->
            <div class="c-userBubble__message zip-ignore" *ngIf="zipCodeErrorMsg">
              <br />
              {{zipCodeErrorMsg}}
            </div>

          </div>

          <div class="c-inputGroup zip-ignore" [ngClass]="{
              'c-inputGroup--isSet': zipInfoBox ? true : false
            }">
            <input class="c-inputGroup__input zip-ignore" placeholder="{{ 'GLOBAL.G013.value' | translate }}"
              [(ngModel)]="zipCode" type="text" (keyup)="changeZip($event)" name="test2" />
            <button class="c-inputGroup__button zip-ignore" [ngClass]="{
                disabled: !zipCode || zipCodeLength !== 5 ? true : false
              }" *ngIf="!zipInfoBox" (click)="showMoreZipInfo('open')" [disabled]="!zipCode || zipCodeLength !== 5">
              <!-- Tallenna -->
              <span *ngIf="generalService.translationMode === true">
                (A019)
              </span>
              {{ "AUTH.A019.value" | translate }}
            </button>
            <button class="c-inputGroup__button--reset zip-ignore" (click)="showMoreZipInfo('close')"
              *ngIf="zipInfoBox">
              <span class="c-icon icon-close zip-ignore"></span>
            </button>
          </div>

          <div class="c-header__deliveryDropdown-text zip-ignore" *ngIf="zipInfoBox && zipCode && zipCodeLength === 5">
            <div class="c-header__deliveryDropdown-footer zip-ignore">
              <div *ngIf="
                  collicoService.deliveryAvailable &&
                    !collicoService.nearestCollicoDeliveryDateAndTime &&
                    zipCode &&
                    zipCodeLength === 5 &&
                    zipCodePref === zipCode &&
                    siteConfigService.siteConfig.availableDeliveryMethods &&
                    siteConfigService.siteConfig.availableDeliveryMethods
                      .noOfDeliveryMethods === 1 &&
                    siteConfigService.siteConfig.availableDeliveryMethods.deliveryMethods.includes(
                      'CHD001'
                    );
                  else show_ok_area_deliverable
                "></div>
              <ng-template #show_ok_area_deliverable>
                <button class="b-action zip-ignore" (click)="closeZipModal()">
                  OK
                </button>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="l-page l-page--navPanelLift l-page--cartPanelLift">
    <div class="c-header__tags c-header__tags--scrollable">
      <div class="c-header__tags-barContainer">
        <div class="c-header__tags-bar" *ngIf="hasSearchTags === true">
          <button class="b-tag b-tag--header" *ngFor="let searchTag of availableSearchTags2"
            (click)="addToSearchTags(searchTag)">
            {{ searchTag.value }}
          </button>
        </div>
        <div class="c-header__tags-bar" *ngIf="hasSearchTags === false">
          <button class="b-tag b-tag--header" *ngFor="let searchTag of availableSearchTags"
            (click)="addToSearchTags(searchTag)">
            {{ searchTag.name }}
          </button>
        </div>
        <a href="javascript:void(0)" class="b-header__tags-toggle" (click)="toggleMore()"
          *ngIf="moreSearchTags2.length && hasSearchTags === true">
          <!-- more -->
          <span *ngIf="generalService.translationMode === true"> (S004) </span>
          {{ "SEARCH.S004.value" | translate }}
        </a>
        <a href="javascript:void(0)" class="b-header__tags-toggle" (click)="toggleMore()"
          *ngIf="moreSearchTags.length && hasSearchTags === false">
          <!-- more -->
          <span *ngIf="generalService.translationMode === true"> (S004) </span>
          {{ "SEARCH.S004.value" | translate }}
        </a>
      </div>
      <div class="c-header__tags-moreContainer" id="headerAllTags" tabindex="-1" *ngIf="displayMore">
        <div class="l-page l-page--navPanelLift l-page--cartPanelLift">
          <div class="c-header__tags-more" *ngIf="hasSearchTags === true">
            <button *ngFor="let item of moreSearchTags2" class="b-tag b-tag--header" (click)="addToSearchTags(item)">
              {{ item.value }}
            </button>
          </div>
          <div class="c-header__tags-more" *ngIf="hasSearchTags === false">
            <button *ngFor="let item of moreSearchTags" class="b-tag b-tag--header" (click)="addToSearchTags(item)">
              {{ item.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </div>  -->