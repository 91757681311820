import { Injectable } from "@angular/core";
import {
  ApiService,
  CacheService,
  CentralStorageService,
} from "src/app/core/services";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DisplayGroupService {
  displayGroupsObs$ = new BehaviorSubject([]);
  displayGroups: any = [];

  constructor(
    private api: ApiService,
    private centralStorageService: CentralStorageService
  ) {}

  async getDisplayGroups() {
    try {
      const vendorCode = this.centralStorageService.getVendorCode();

      const { status_code, result } = await this.api
        .get("/display_groups", { vendor_tag: vendorCode })
        .toPromise();

      if (status_code === 200 && result) {
        const displayGroups = result as any;
        this.displayGroups = result;
        this.displayGroupsObs$.next(displayGroups);
        return result;
      }
      return [];
    } catch (error) {
      throw error;
    }
  }

  async getSearchTags(groupCode?: string) {
    // if (groupCode || groupCode === "frontPage") {
    //   try {
    //     const { status_code, result } = await this.api
    //       .get("/tags", { code: groupCode === "frontPage" ? "" : groupCode })
    //       .toPromise();
    //     if (status_code === 200 && result) {
    //       this.displayGroups = result;
    //       return result;
    //     }
    //     return [];
    //   } catch (error) {
    //     throw error;
    //   }
    // }
    return [];
  }

  get getDisplayGroupInstance() {
    return this.displayGroups;
  }

  getDisplayGroupParentName(id: string) {
    let currentGroup = null;

    if (id.length === 6) {
      let parentId = id.substr(0, 3);
      currentGroup = this.displayGroups.find((a) => a.code === parentId);

      if (currentGroup) {
        return currentGroup.name;
      }
    }
  }

  getDisplayGroupName(id: string) {
    let parentGroup = null;
    let currentGroup = null;

    if (this.displayGroups.length && id.length) {
      if (id.length === 3) {
        currentGroup = this.displayGroups.find((a) => a.code === id);
      }

      if (id.length === 6) {
        let parentId = id.substr(0, 3);
        parentGroup = this.displayGroups.find((a) => a.code === parentId);
        if (
          parentGroup &&
          parentGroup.display_groups &&
          parentGroup.display_groups.length
        ) {
          currentGroup = parentGroup.display_groups.find((a) => a.code === id);
        }
      }
    }

    if (currentGroup && currentGroup.name) {
      return currentGroup.name;
    }

    return id;
  }

  // return a display group subscribe too.. to detect changes on fly.
}
