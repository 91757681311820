import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { map } from "rxjs/operators";

import { IQuery } from "../ITypes";
import { Observable } from "rxjs";

interface IResult {
  from: number;
  to: number;
  per_page: string;
  current_page: number;
  first_page_url: string;
  next_page_url: string;
  prev_page_url: string;
  data?: Array<Object>;
  items?: Array<Object>;
  summary?: Object;
  postal_codes?: string[];
  restricted_postal_codes?: { postal_codes: string[] };
  total?: number;
  currentBookings?: number;
}

interface IResponse {
  charset: string;
  status_code: number;
  result: IResult;
}

@Injectable()
export class ApiService {
  /**
   * Creates an instance of ApiService.
   * @param {HttpClient} _http
   * @memberof ApiService
   */
  constructor(private _http: HttpClient) {}

  /**
   * this is to get
   * requested data
   * @param {string} url
   * @param {IQuery} [query]
   * @returns
   * @memberof ApiService
   */
  get(
    url: string,
    query?: IQuery
  ): Observable<{ status_code: number; result: any }> {
    let { _url, params } = this.generateUrl(url, query);
    return this._http
      .get(_url, { observe: "body", params })
      .pipe(
        map(({ status_code, result }: IResponse) => ({ status_code, result }))
      );
  }

  /**
   * this is to get
   * request headers
   * @param {string} url
   * @param {IQuery} [query]
   * @returns
   * @memberof ApiService
   */
  getResponseHeaders(url: string, query?: IQuery) {
    let { _url, params } = this.generateUrl(url, query);
    return this._http
      .get(_url, { observe: "response", params })
      .pipe(map((res) => ({ status_code: res.status, headers: res.headers })));
  }

  /**
   * this is to get a record
   * by its id
   * @param {string} url
   * @param {string} id
   * @returns
   * @memberof ApiService
   */
  getById(url: string, id: string, query?: IQuery) {
    let uri = `${url}/${id}`;
    let { _url, params } = this.generateUrl(uri, query);
    return this._http
      .get(_url, { observe: "body", params })
      .pipe(map(({ status_code, result }: any) => ({ status_code, result })));
  }

  /**
   * this is to request create data
   * @param {Object} data
   * @param {string} url
   * @param {IQuery} [query]
   * @returns
   * @memberof ApiService
   */
  post(data: Object, url: string, query?: IQuery) {
    let { _url, params } = this.generateUrl(url, query);
    return this._http
      .post(_url, data, { observe: "body", params })
      .pipe(map(({ status_code, result }: any) => ({ status_code, result })));
  }

  patch(data: Object, url: string, query?: IQuery) {
    let { _url, params } = this.generateUrl(url, query);
    return this._http
      .patch(_url, data, { observe: "body", params })
      .pipe(map(({ status_code, result }: any) => ({ status_code, result })));
  }

  /**
   * this is to request update data
   * @param {Object} data
   * @param {string} url
   * @param {IQuery} [query]
   * @returns
   * @memberof ApiService
   */
  put(data: Object, url: string, query?: IQuery) {
    let { _url, params } = this.generateUrl(url, query);
    return this._http
      .put(_url, data, { observe: "body", params })
      .pipe(map(({ status_code, result }: any) => ({ status_code, result })));
  }

  /**
   * this is to request delete data
   * @param {string} url
   * @param {IQuery} [query]
   * @returns
   * @memberof ApiService
   */
  delete(url: string, query?: IQuery) {
    let { _url, params } = this.generateUrl(url, query);
    return this._http
      .delete(_url, { observe: "response", params })
      .pipe(map(({ status_code, result }: any) => ({ status_code, result })));
  }

  /**
   * this is to generate Api url
   * with params
   * @param {string} url
   * @param {IQuery} [query]
   * @returns
   * @memberof ApiService
   */
  generateUrl(url: string, query?: IQuery) {
    let _url: string = url;
    let params: HttpParams;
    let result = { _url, params };
    if (query) {
      params = new HttpParams();
      Object.keys(query).forEach((key) => {
        params = params.append(key, query[key]);
      });
      result.params = params;
    }
    return result;
  }
}
