export const environment = {
  production: false,
  svAPIKey: "OLU321",
  baseUrl: "https://staging.public.api.lafka.dev/api/v1",
  googleAppId: "",
  facebookAppId: "",
  firebase: {
    apiKey: "AIzaSyACFof8x2cxtQUozDq6N0maT6uZVfYwnPU",
    authDomain: "olutmaailma-staging.firebaseapp.com",
    projectId: "olutmaailma-staging",
    storageBucket: "olutmaailma-staging.appspot.com",
    messagingSenderId: "109246326280",
    appId: "1:109246326280:web:99b1709ccfb47c6b3613bd",
    measurementId: "G-GT9LMBYE86",
  },
};
