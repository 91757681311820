import { Injectable } from "@angular/core";

import { siteConfig } from "site-config";
import { IConfig } from "../ITypes";
import { CacheService } from "./cache.service";
import { MyCookieService } from "./cookie.service";

@Injectable({
  providedIn: "root",
})
export class SiteConfigService {
  // defaults
  siteConfig: IConfig = siteConfig;

  constructor(
    private cacheService: CacheService,
    private cookieService: MyCookieService
  ) {}

  checkSiteVersion() {
    let currentVersion = this.cacheService.get("version");
    let siteVersion = this.siteConfig.version;

    if (currentVersion !== siteVersion) {
      this.cacheService.removeAll();
      this.cookieService.deleteAll();

      this.cacheService.set("version", siteVersion);
    }
  }

  get getCurrentTenant() {
    return this.siteConfig.tenant;
  }

  get getTenantTitle() {
    return this.siteConfig.title;
  }

  // update the siteConfig from API later on...
}
