import { Injectable } from "@angular/core";
import { AngularFireAnalytics } from "@angular/fire/analytics";
import { AngularFireAuth } from "@angular/fire/auth";

import { CacheService } from "./cache.service";
import { DisplayGroupService } from "src/app/commons/services";
import { SiteConfigService } from "./site-config.service";

interface ProductEventDetail {
  event_type: string;
  product_id: string;
  product_price?: string;
  product_count?: number;
  event_by?: string;
  user_type?: string;
}

interface MetaData {
  page_location: string;
  page_location_route: string;
  page_title: string;
  meta_title: string;
  meta_description: string;
  meta_keywords: string;
  event_by?: string;
  product_id?: string;
}

interface ProductViewEventDetail {
  product_id: string;
  product_price?: string;
  event_by?: string;
  user_type?: string;
}

interface ProductListEventDetail {
  list_name: string;
  products: string[];
  event_by?: string;
  user_type?: string;
}

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  currentTenant: string = "";
  currentUser: string = "";
  currentProductId: number;
  isLoggedInUser: boolean = false;

  /**
   *Creates an instance of AnalyticsService.
   * @param {AngularFireAnalytics} analytics
   * @param {afAuth} AngularFireAuth
   * @memberof AnalyticsService
   */
  constructor(
    private afAnalytics: AngularFireAnalytics,
    private afAuth: AngularFireAuth,
    private cacheService: CacheService,
    private displayGroupService: DisplayGroupService,
    private siteConfigService: SiteConfigService
  ) {
    this.currentTenant = this.siteConfigService.getTenantTitle;
  }

  /**
   *
   *Sets the current logged in or guest user for analytics.
   * @param {string} [newUserID]
   * @memberof AnalyticsService
   */
  async setUser(newUserID?: string) {
    let userID = await this.cacheService.get("UUTID");
    if (newUserID) {
      // purely new user here...
      this.afAnalytics.setUserId(newUserID);
      this.currentUser = newUserID;
    } else {
      this.afAuth.authState.subscribe((authState) => {
        if (authState && authState.email) {
          // logged in user here ...
          this.afAnalytics.setUserId(authState.email);
          this.currentUser = authState.email;
          this.isLoggedInUser = true;
        } else {
          // returning user here (not logged in)...
          this.afAnalytics.setUserId(userID);
          this.currentUser = userID;
        }
      });
    }
  }

  /**
   *
   *Fires FireBaseAnalytics product event.
   * @param {string} eventName
   * @param {string} eventType
   * @param {string} [productID]
   * @param {string} [productPrice]
   * @param {number} [productCount]
   * @memberof AnalyticsService
   */
  async productEvent(
    eventName: string, // eventNames can be =>   // cart_add_product, cart_remove_product, cart_increment_product, cart_decrement_product, cart_quantity_exceed,  cart_checkout,
    eventType: string,
    productID?: string,
    productPrice?: string,
    productCount?: number,
    product?: any
    // add more incoming properties here when needed...
  ) {
    this.afAuth.authState.subscribe((authState) => {
      if (authState && authState.email) {
        this.isLoggedInUser = true;
      } else {
        this.isLoggedInUser = false;
      }
    });

    // let eventDetails: ProductEventDetail = {
    //   event_type: eventType,
    //   product_id: productID,
    //   product_price: productPrice || null,
    //   product_count: productCount || null,
    //   event_by: this.currentUser || null,
    //   user_type: this.isLoggedInUser ? "Logged in user" : "Guest user",
    // };

    let products = this.makeProductImpression([product], null, true);
    let ecommerce: any = {};

    if (eventName === "addToCart") {
      ecommerce = { add: { products } };
    }

    if (eventName === "removeFromCart") {
      ecommerce = { remove: { products } };
    }

    // await this.afAnalytics.logEvent(eventName, eventDetails);
    (<any>window).dataLayer.push({ event: eventName, ecommerce });
  }

  /**
   *
   *Fires FireBaseAnalytics checkout event.
   * @param {string} [status]
   * @memberof AnalyticsService
   */
  async checkoutEvent(
    status?: string,
    cartId?: number,
    user_uuid?: string,
    products?: any,
    verifiedOrder?: any
  ) {
    let email = "";
    let eventName = "checkout";

    await this.afAuth.authState.subscribe(async (authState) => {
      await authState;
      if (authState && authState.email) {
        email = authState.email;
      } else {
        email = "guestuser"; // get guest user email, other data here...
      }

      // let checkoutObj = {
      //   payment_status: status ? status : "processing",
      //   user_email: email,
      //   cart_id: cartId || "",
      //   user_id: user_uuid || "",
      // };

      let step = 0;

      if (
        status === "unprocessed" ||
        status === "pending" ||
        status === "failed"
      ) {
        step = 1;
      }

      if (status === "order_clicked") {
        step = 2;
      }

      if (status === "completed") {
        eventName = "purchase";
        step = 3;
      }

      let checkoutObj: any = {
        ecommerce: {
          checkout: {
            actionField: { step },
            products: this.makeProductImpression(products, null, true),
          },
        },
      };

      if (status === "completed" && verifiedOrder) {
        eventName = "purchase";
        checkoutObj = {
          ecommerce: {
            purchase: {
              actionField: {
                id: verifiedOrder.order_number, // Transaction ID. Required for purchases and refunds.
                affiliation: this.currentTenant,
                revenue: verifiedOrder.amount, // Total transaction value (incl. tax and shipping)
                tax: verifiedOrder.tax_amount,
                shipping: verifiedOrder.delivery_charge,
                coupon: verifiedOrder.discount_code || "",
              },
              products: this.makeProductImpression(products, null, true),
            },
          },
        };
      }

      // return await this.afAnalytics.logEvent(eventName, checkoutObj);
      (<any>window).dataLayer.push({
        event: eventName,
        ecommerce: checkoutObj.ecommerce,
      });
    });
  }

  /**
   *
   *Fires FireBaseAnalytics screenView event to track each route / screen change.
   * @param {string} [pageFullRoute]
   * @param {string} [pageSubRoute]
   * @param {string} [pageTitle]
   * @param {string} [metaTitle]
   * @param {string} [metaDescription]
   * @param {string} [metaKeywords]
   * @param {string} [productId]
   * @memberof AnalyticsService
   */
  async screenView(pageFullRoute?: string, pageTitle?: string) {
    // this.afAnalytics.logEvent("screen_view", screenViewObj);
    // let document = this.generalService.getDocument;
    // let title = document.title;

    (<any>window).dataLayer.push({
      event: "pageView",
      pageTitle,
      pageURL: pageFullRoute,
    });
  }

  /**
   *z
   * Makes meta data and retuns a metaData object
   * @param {string} pathname
   * @param {string} [id]
   * @returns {MetaData}
   * @memberof AnalyticsService
   */
  makeMetaData(pathname: string, id?: string) {
    let metaObj = {
      page_title: "",
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      pathname: "",
      productId: null,
    };

    //homepage
    if (pathname === "/") {
      metaObj.page_title = "Home Page";
      metaObj.meta_title = "Home Page | StoreVerse";
      metaObj.meta_description =
        "Welcome to StoreVerse | Home of amazing products";
      metaObj.meta_keywords = "Welcome, Home, StoreVerse";
      metaObj.pathname = pathname;
      return metaObj;
    }

    //searchpage
    let n = pathname.search("search");
    if (n !== -1) {
      metaObj.page_title = "Search Page";
      metaObj.meta_title = "Search Page | StoreVerse";
      metaObj.meta_description =
        "Search amazing products on StoreVerse search page";
      metaObj.meta_keywords = "search, products, StoreVerse";
      metaObj.pathname = pathname;
      return metaObj;
    }

    //productpage
    n = pathname.search("p");
    if (n !== -1) {
      metaObj.page_title = "Product Page";
      metaObj.meta_title = "Product Page | StoreVerse";
      metaObj.meta_description =
        "View amazing products on StoreVerse product page";
      metaObj.meta_keywords = "productdescription, productcategory, StoreVerse";
      metaObj.pathname = pathname;
      metaObj.productId = this.currentProductId;
      return metaObj;
    }

    //cartpage
    n = pathname.search("cart");
    if (n !== -1) {
      metaObj.page_title = "Cart Page";
      metaObj.meta_title = "Cart Page | StoreVerse";
      metaObj.meta_description =
        "Checkout your products on StoreVerse cart page";
      metaObj.meta_keywords = "Checkout, cart, StoreVerse";
      metaObj.pathname = pathname;
      return metaObj;
    }

    //profilepage
    n = pathname.search("profile");
    let k = pathname.search("dashboard");
    if (n !== -1 || k !== -1) {
      metaObj.page_title = "Profile Page";
      metaObj.meta_title = "Profile Page | StoreVerse";
      metaObj.meta_description = "User Profile StoreVerse ";
      metaObj.meta_keywords = "Profile, User, StoreVerse";
      metaObj.pathname = pathname;
      return metaObj;
    }

    //favoritespage
    n = pathname.search("favorites");
    if (n !== -1) {
      metaObj.page_title = "Favorites Page";
      metaObj.meta_title = "Favorites Page | StoreVerse";
      metaObj.meta_description = "User's Favorites StoreVerse ";
      metaObj.meta_keywords = "Favorites, User, StoreVerse";
      metaObj.pathname = pathname;
      return metaObj;
    }

    n = pathname.search("r");
    if (n !== -1) {
      metaObj.page_title = "Recipes Page";
      metaObj.meta_title = "Recipes Page | StoreVerse";
      metaObj.meta_description = "Best recipes StoreVerse ";
      metaObj.meta_keywords = "Recipes, Food, StoreVerse";
      metaObj.pathname = pathname;
      return metaObj;
    }
  }

  /**
   *
   *Fires FireBaseAnalytics product view event.
   * @param {string} [productID]
   * @param {string} [productPrice]
   * @memberof AnalyticsService
   */
  async productViewEvent(
    productID?: string,
    productPrice?: string,
    product?: any
    // add more incoming properties here when needed...
  ) {
    let eventName = "productView";
    this.afAuth.authState.subscribe((authState) => {
      if (authState && authState.email) {
        this.isLoggedInUser = true;
      } else {
        this.isLoggedInUser = false;
      }
    });

    // let eventDetails: ProductViewEventDetail = {
    //   product_id: productID,
    //   product_price: productPrice || null,
    //   event_by: this.currentUser || null,
    //   user_type: this.isLoggedInUser ? "Logged in user" : "Guest user",
    // };

    // let eventDetails = {
    let ecommerce = {
      detail: {
        actionField: {
          list: this.displayGroupService.getDisplayGroupName(product.D001),
        },
        products: this.makeProductImpression([product]),
      },
    };
    // };

    // await this.afAnalytics.logEvent("productView", eventDetails);
    (<any>window).dataLayer.push({ event: eventName, ecommerce });
  }

  async productListEvent(pathname: string, products: string[]) {
    let listName = null;
    let isFound = false;

    if (pathname && products && products.length) {
      //searchpage
      let s = pathname.search("search");
      if (s !== -1 && !isFound) {
        listName = "SearchPage";
        isFound = true;
      }

      //productpage
      let p = pathname.search("/p/");
      if (p !== -1 && !isFound) {
        listName = "ProductPage";
        isFound = true;
      }

      if (pathname === "/") {
        listName = "FrontPage";
        isFound = true;
      }

      // let eventDetails: ProductListEventDetail = {
      //   list_name: listName,
      //   products: products,
      //   event_by: this.currentUser || null,
      //   user_type: this.isLoggedInUser ? "Logged in user" : "Guest user",
      // };
      // await this.afAnalytics.logEvent("view_product_list", eventDetails);

      let eventName = "productImpression";
      let impressions = this.makeProductImpression(products, listName);

      let ecommerce = {
        currencyCode: "EUR",
        impressions,
      };

      if (impressions && impressions.length && impressions.length > 0) {
        // await this.afAnalytics.logEvent(eventName, ecommerce);
        (<any>window).dataLayer.push({ event: eventName, ecommerce });
      }
    }
  }

  makeProductImpression(
    products: any,
    listName?: string,
    cartAction?: boolean
  ) {
    if (products && products.length) {
      let impressionsArray = [];

      for (let i = 0; i < products.length; i++) {
        const product = products[i];
        // impressionsArray.push({
        //   name: product.F001 || product.F002,
        //   id: product.C001,
        //   price: product.F018,
        //   brand: "Brand name, if any",
        //   category: this.renderDisplayGroupName(product.D001),
        //   list: listName ? listName : null,
        //   position: listName ? i + 1 : null,
        // });

        let impression: any = {
          name: product.F001 || product.F002,
          id: product.C001,
          price: product.F018,
          brand: product.F101 || "",
          category: this.renderDisplayGroupName(product.D001),
        };

        if (listName) {
          impression.list = listName;
          impression.position = i + 1;
        }

        if (cartAction && cartAction === true) {
          impression.quantity = product.quantity;
        }

        impressionsArray.push(impression);
      }

      if (impressionsArray.length === products.length) {
        return impressionsArray;
      }
    }
  }

  renderDisplayGroupName(id: string) {
    if (id.length === 6) {
      let dName = this.displayGroupService.getDisplayGroupName(id);
      let dParentName = this.displayGroupService.getDisplayGroupParentName(id);
      let displayGroupFullName = dName + " | " + dParentName;

      return displayGroupFullName;
    }
  }

  async signupEvent() {
    (<any>window).dataLayer.push({
      event: "registerSuccess",
      eventCategory: "Users",
      eventAction: "Registered",
      eventLabel: "",
    });
  }
}
