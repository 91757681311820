import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { TranslateModule } from "@ngx-translate/core";
import { NgDynamicBreadcrumbModule } from "ng-dynamic-breadcrumb";
import { NgxMenusModule } from "@ngx-lite/menus";
import { ContentLoaderModule } from "@ngneat/content-loader";
import { TooltipModule, TooltipOptions } from "ng2-tooltip-directive";

import { FloorPipe } from "./pipes/floor.pipe";
import { CamelToTitlePipe } from "./pipes/camel-to-title.pipe";
import { NavbarComponent } from "./navbar/navbar.component";
import { SearchComponent } from "./search/search.component";
import { FooterComponent } from "./footer/footer.component";
import { ProductGridComponent } from "./product-grid/product-grid.component";
import { ProductCardComponent } from "./product-card/product-card.component";
import { ProductTreeComponent } from "./product-tree/product-tree.component";
import { ChipsInputComponent } from "./chips-input/chips-input.component";
import { AuthModalComponent } from "./auth/auth.component";
import { ShortcutGridComponent } from "./shortcut-grid/shortcut-grid.component";
import { BuyBtnComponent } from "./buy-btn/buy-btn.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { TabComponent } from "./tab/tab.component";
import { TabviewComponent } from "./tab/tabview/tabview.component";
import { CartPanelComponent } from "./cart-panel/cart-panel.component";
import { ProductCardLoaderComponent } from "./product-card-loader/product-card-loader.component";
import { ProductDetailLoaderComponent } from "./product-detail-loader/product-detail-loader.component";
import { ModalComponent } from "./modal/modal.component";
import { CartPanelLoaderComponent } from "./cart-panel-loader/cart-panel-loader.component";
import { CartProductLoaderComponent } from "./cart-product-loader/cart-product-loader.component";
import { ThrottleClickDirective } from "./directives/throttle-click/throttle-click.directive";
import {
  ShopCurrencyPipe,
  ShopCurrencyDigitsPipe,
} from "./pipes/shop-currency.pipe";
import { CookieConsentComponent } from "./cookie-consent/cookie-consent.component";
import { NotificationBarComponent } from "./notification-bar/notification-bar.component";
import { DayNamePipe } from "./pipes/day-name.pipe";

const modules = [
  NavbarComponent,
  NotificationBarComponent,
  SearchComponent,
  ProductGridComponent,
  CamelToTitlePipe,
  FloorPipe,
  ShopCurrencyPipe,
  ShopCurrencyDigitsPipe,
  ProductCardComponent,
  ProductTreeComponent,
  FooterComponent,
  ChipsInputComponent,
  AuthModalComponent,
  ShortcutGridComponent,
  BuyBtnComponent,
  BreadcrumbComponent,
  TabComponent,
  TabviewComponent,
  CartPanelComponent,
  ProductDetailLoaderComponent,
  ModalComponent,
  CartProductLoaderComponent,
  CookieConsentComponent,
  ProductCardLoaderComponent,
  DayNamePipe,
];

const tootipOption: TooltipOptions = {
  placement: "bottom",
  theme: "dark",
  hideDelayAfterClick: 100,
  "z-index": 1,
  "hide-delay-mobile": 100,
  "hide-delay": 200,
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    TranslateModule,
    NgDynamicBreadcrumbModule,
    NgxMenusModule,
    ContentLoaderModule,
    TooltipModule.forRoot(tootipOption),
  ],
  exports: [modules, TranslateModule],
  declarations: [
    modules,
    ProductCardLoaderComponent,
    CartPanelLoaderComponent,
    ThrottleClickDirective,
  ],
  entryComponents: [AuthModalComponent],
})
export class CommonsModule {}
