import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Router } from "@angular/router";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import {
  AuthService,
  SiteConfigService,
  CartService,
} from "src/app/core/services/";
import {
  IUser,
  IUserLoginCredentails,
  IConfig,
  ILoginErrors,
} from "src/app/core/ITypes";
import { GeneralService, ShopService, UserService } from "../services";
import { TranslateService } from "@ngx-translate/core";

/**
 * Represents Authentication
 * Modal Component
 * @export AuthModalComponent
 * @class AuthModalComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: "sv-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"],
})
export class AuthModalComponent implements OnInit, OnDestroy {
  // defaults
  // @Input() caption: string;
  private unsubscribe$ = new Subject<void>();
  user: IUser = null;
  signupBtnText: string = "Sign Up";
  loginBtnText: string = "Sign In";
  loggedIn: boolean = false;
  newUserCreated: boolean = false;
  interactionsDisabled: boolean = false;
  emailBox: boolean = false;
  config: IConfig["auth"] = null;

  authObj: IUserLoginCredentails = {
    name: "",
    pass: "",
    email: "",
    location: "",
    phone: "",
    birthDate: "",
  };
  errors: ILoginErrors = {
    loginError: "",
    signUpError: "",
    socialLoginError: "",
    popUpClosedError:
      "The popup has been closed by the user before finalizing the operation.",
    userCancelledPopupError:
      "IdP denied access. This usually happens when user refuses to grant permission.",
    anotherPopupError:
      "This operation has been cancelled due to another conflicting popup being opened.",
  };

  /**
   * Creates an instance of AuthModalComponent.
   * @param {AuthService} authService
   * @memberof AuthModalComponent
   */
  constructor(
    public generalService: GeneralService,
    private authService: AuthService,
    private siteConfig: SiteConfigService,
    private cartService: CartService,
    private shopService: ShopService,
    private userService: UserService,
    private translate: TranslateService,
    private router: Router
  ) {
    const { auth } = this.siteConfig.siteConfig;
    this.config = auth;
  }

  /**
   * We subscribe if user exist
   * We subscribe if user is loggedIn
   * @name ngOnInit
   * @memberof AuthModalComponent
   */
  ngOnInit() {
    this.authService.isLoggedIn
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loggedIn) => {
        this.loggedIn = loggedIn;
        if (this.loggedIn) {
          this.generalService.setModalState = {
            ...this.generalService.getModalState,
            isOpen: false,
          };
        }
      });
    this.authService.observeUser
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        this.user = user;
      });
  }

  /**
   * unsubscribe to observables
   * @name ngOnDestroy
   * @memberof AuthModalComponent
   */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Fires google auth from authService
   * @name googleAuth
   * @memberof AuthModalComponent
   */
  async googleAuth() {
    try {
      let response = await this.authService.googleLogin();
      if (response) {
        let { message, code } = response;
        if (message && code) {
          this.errors.socialLoginError = message;
        }
      }

      return response;
    } catch (error) {
      this.errors.socialLoginError = error;
      console.error("googleAuth error >>> ", error);
    }
  }

  /**
   * Fires facebook auth from authService
   * @name facebookAuth
   * @memberof AuthModalComponent
   */
  async facebookAuth() {
    try {
      let response = await this.authService.facebookLogin();

      if (response) {
        let { message, code } = response;
        if (message && code) {
          this.errors.socialLoginError = message;
        }
      }

      return response;
    } catch (error) {
      this.errors.socialLoginError = error;
      console.error("googleAuth error >>> ", error);
    }
  }

  /**
   * Fires singup from auth service
   * @name signUpViaEmail
   * @memberof AuthModalComponent
   */
  async signUpViaEmail() {
    this.interactionsDisabled = true;
    this.signupBtnText = "registering...";

    try {
      let response = await this.authService.signUp(this.authObj);
      if (response) {
        let { message, code, user } = response;

        this.signupBtnText = "Submit";
        this.interactionsDisabled = false;

        if (message && code) {
          this.errors.signUpError = message;
        } else if (user && user.email) {
          this.generalService.setModalState = {
            ...this.generalService.getModalState,
            isOpen: false,
          };
        }
        return response;
      }
    } catch (error) {
      this.signupBtnText = "Submit";
      this.interactionsDisabled = false;

      this.errors.signUpError = error;
      console.error("googleAuth error >>> ", error);
    }
  }

  /**
   * Fires login via email from auth service
   * @name logInViaEmail
   * @memberof AuthModalComponent
   */
  async logInViaEmail() {
    this.loginBtnText = "Signing in...";
    this.interactionsDisabled = true;

    this.authService.logIn(this.authObj).then(async (response) => {
      if (response) {
        let { message, code, user } = response;

        this.interactionsDisabled = false;

        if (message && code) {
          this.errors.loginError = message;

          if (["auth/wrong-password", "auth/internal-error"].includes(code)) {
            this.errors.loginError = this.translate.instant("AUTH.A023.value");
          }
        } else if (user && user.email) {
          this.generalService.setModalState = {
            ...this.generalService.getModalState,
            isOpen: false,
          };

          let document = this.generalService.getDocument;
          setTimeout(() => {
            document.location.href = document.location.href;
          }, 2000);
        }

        this.loginBtnText = "Sign in";
      }
    });
  }

  /**
   * Hides and shows email signUp/login box
   * @name showEmailBox
   * @memberof AuthModalComponent
   */
  showEmailBox() {
    this.emailBox = !this.emailBox;
  }

  showRegistration() {
    this.generalService.setCartPanelState = false;
    this.generalService.setModalState = {
      id: "1",
      name: "signUpModal",
      isOpen: true,
    };
  }

  forgotPasswordModal() {
    this.generalService.setCartPanelState = false;
    this.generalService.setModalState = {
      id: "4",
      name: "forgotPasswordModal",
      isOpen: true,
    };
  }
}
