import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IProduct } from "../product-card/IProduct";
import { CartService } from "src/app/core/services";
import { GeneralService } from "../services/general.service";
import { ProductService } from "../services/product.service";
import { IPreference } from "src/app/core/ITypes";
import { ShopService, UserService } from "../services";
import { CollicoService } from "../services/collico.service";
@Component({
  selector: "sv-cart-panel",
  templateUrl: "./cart-panel.component.html",
  styleUrls: ["./cart-panel.component.scss"],
  providers: [ProductService],
})
export class CartPanelComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  cart = { id: 0, products: [], total: 0 };
  cartTotal: number = 0;
  cartTotalProducts: number = 0;
  products: Array<IProduct> = [];
  navbarHeight: string;
  loading: boolean = true;

  constructor(
    public generalService: GeneralService,
    public userService: UserService,
    private cartService: CartService,
    private shopService: ShopService,
    public collicoService: CollicoService
  ) {
    this.cart = this.cartService.cartData || this.cart;
  }

  async ngOnInit() {
    this.cartService.cartDataObs$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((cartData) => {
        if (cartData) {
          const { products, total } = cartData;
          this.cart.products = products;
          this.cartTotal = total;
          this.cartTotalProducts = this.cart.products.length;

          // checking limited availability products
          this.collicoService.checkLimitedAvailability(this.cart.products);
        }
      });

    // observe products loading
    this.shopService
      .observeCartLoading()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loading) => {
        this.loading = loading;
      });

    // observe cart panel top margin
    this.generalService.observeNavbarHeight
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((height) => {
        let nav = document.getElementById("navBar");
        if (nav.offsetWidth > 680) {
          this.navbarHeight = height > 0 ? `${nav.offsetHeight}px` : "0";
        } else {
          this.navbarHeight = nav.offsetHeight + "px";
        }
      });
  }

  /**
   * unsubscribe to observables
   * @name ngOnDestroy
   * @memberof CartPanelComponent
   */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleToggleCartPanel() {
    this.generalService.setCartPanelState =
      !this.generalService.getCartPanelState;
  }
}
