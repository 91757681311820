import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dayName",
})
export class DayNamePipe implements PipeTransform {
  transform(dateString: string, locale: string = "en"): string {
    const dateParts = dateString.split("-");
    const date = new Date(
      parseInt(dateParts[0], 10), // year
      parseInt(dateParts[1], 10) - 1, // month (0-based index)
      parseInt(dateParts[2], 10) // day
    );
    const day = date.getDay();

    const dayNames = {
      en: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      fi: [
        "Sunnuntai",
        "Maanantai",
        "Tiistai",
        "Keskiviikko",
        "Torstai",
        "Perjantai",
        "Lauantai",
      ],
    };
    const dayAbbreviations = {
      en: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      fi: ["Su", "Ma", "Ti", "Ke", "To", "Pe", "La"],
    };

    const dayNamesForLocale = dayNames[locale] || dayNames["en"];
    const dayAbbreviationsForLocale =
      dayAbbreviations[locale] || dayAbbreviations["en"];

    return dayAbbreviationsForLocale[day];
  }
}
