<div (scroll)="scrollMe($event)" class="c-site-container" [ngClass]="{
    'is-navPanel--isVisible': isTreeVisible,
    'is-cartPanel--isVisible': isCartPanelVisible
  }">
  <!-- Header -->
  <sv-navbar class="c-headerNavbarContainer" #svNavbar id="navBar"></sv-navbar>

  <!-- Notification Bar -->
  <sv-notification-bar></sv-notification-bar>

  <!-- Search -->
  <sv-search></sv-search>

  <!-- Product Tree -->
  <sv-product-tree *ngIf="isTreeVisible" class="c-navPanelContainer"></sv-product-tree>

  <!-- Screens -->
  <router-outlet></router-outlet>

  <!-- Cart Panel -->
  <sv-cart-panel *ngIf="isCartPanelVisible" class="c-cartPanelContainer"></sv-cart-panel>

  <!-- Modals -->
  <sv-modal></sv-modal>

  <!-- Footer -->
</div>

<sv-footer></sv-footer>