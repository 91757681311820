import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { SiteConfigService } from "./site-config.service";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private siteConfigService: SiteConfigService
  ) {}

  setTheme() {
    let {
      primary,
      secondary,
      headerBg,
      headerFont,
      footerBg,
      footerFont,
      fontFamily,
      fontTitle,
      bodyBgColor,
      textColor,
      fontPrice,
      priceDiscountTextColor,
      buyButtonBgColor,
      buyButtonIcon,
      buyButtonColor,
      buyButton2BgColor,
    } = this.siteConfigService.siteConfig.theme;
    let brandColorDarken = this.LightenDarkenColor(primary, -50);

    this.document.documentElement.style.cssText = `
    --brandColor : ${primary};
    --secondaryColor : ${secondary};
    --brandColorDarken : ${brandColorDarken};
    --headerBg: ${headerBg};
    --headerFont: ${headerFont};
    --footerBg: ${footerBg};
    --footerFont: ${footerFont};
    --fontFamily: ${fontFamily};
    --fontTitle: ${fontTitle};
    --bodyBgColor:${bodyBgColor};
    --textColor:${textColor};
    --fontPrice:${fontPrice};
    --priceDiscountTextColor:${priceDiscountTextColor};
    --buyButtonBgColor:${buyButtonBgColor};
    --buyButtonIcon:${buyButtonIcon};
    --buyButtonColor:${buyButtonColor};
    --buyButton2BgColor:${buyButton2BgColor};
    `;
  }

  setVariable(name, value) {
    this.document.documentElement.style.setProperty(name, value);
  }

  LightenDarkenColor(col, amt) {
    var usePound = false;

    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }
}
