import { Injectable, Inject, InjectionToken } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { TranslateLoader } from "@ngx-translate/core";
import { GeneralService } from "./commons/services";

@Injectable()
export class MyTranslationsLoader extends TranslateLoader {
  contentHeader = new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  });

  constructor(
    private httpClient: HttpClient,
    private generalService: GeneralService
  ) {
    super();
  }

  getTranslation(lang: string): Observable<any> {
    let document = this.generalService.getDocument;

    const path = `i18n/${lang}.json?v=${this.generalService.getRandomInt(
      1,
      99999
    )}`;
    const origin = document.location.origin;
    let url = `${origin}/${path}`;
    // change ^this API address to load translation externally...
    // also change the check in interceptor for i18n

    //  check for storverse staging domain.
    if (document.location.href.includes("/storeverse")) {
      url = `${origin}/storeverse/${path}`;
    }

    return this.httpClient
      .get(url, { headers: this.contentHeader })
      .pipe(catchError((_) => this.httpClient.get(path)));
  }
}
