<!-- cartPanel start -->
<div class="l-page">
  <div class="c-cartPanel" [style.top]="navbarHeight">
    <button (click)="handleToggleCartPanel()" class="b-cartPanel--close">
      <span class="b-cartPanel--close__icon icon-close" aria-hidden="true"></span>
      <span class="b-cartPanel--close__title visuallyhidden">Close</span>
    </button>
    <div class="c-cartPanel__header">
      <a [routerLink]="'/cart'" class="b-action b--extended">
        {{ "GLOBAL.G030.value" | translate }}
      </a>
      <div class="c-cartPanel__total">
        <div class="c-cartPanel__totalAmount" [innerHTML]="
            (cartTotalProducts == 1 ? 'GLOBAL.G045.value' : 'GLOBAL.G044.value')
              | translate
                : {
                    count:
                      '<strong class=\'c-cartPanel__totalUnit\'>' +
                      cartTotalProducts +
                      '</strong>'
                  }
          "></div>
        <div class="c-cartPanel__totalSum">
          <strong class="c-cartPanel__totalUnit" *ngIf="cartTotalProducts && cartTotalProducts > 0; else noProducts">{{
            cartTotal.toFixed(2) | shopCurrency }}</strong>
          <ng-template #noProducts>
            <strong class="c-cartPanel__totalUnit">
              {{ "0.00" | shopCurrency }}
            </strong>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="c-cartPanel__body">
      <!-- content loader -->
      <div *ngIf="loading">
        <sv-cart-panel-loader></sv-cart-panel-loader>
      </div>

      <div class="c-productShortList" *ngIf="!loading">
        <!-- products list -->
        <div class="c-productShortList__item" *ngFor="let product of cart.products; index as i" [ngClass]="{
            limitedAvailabilityProducts:
              product.F020 &&
              product.F020 === true &&
              collicoService.collicoZip &&
              !collicoService.collicoAvailable
                ? true
                : false
          }">
          <div class="c-productShortList__item-imageContainer">
            <span *ngIf="generalService.debugMode">
              <small> [F000] </small>
            </span>
            <img [alt]="product.F001" [src]="generalService.resizeImage(product.F000)"
              class="c-productShortList__item-image" />
          </div>
          <div class="c-productShortList__item-body">
            <a [routerLink]="
                '/p/' + (product.F001 || product.F002) + '/' + product.C001
              " [queryParams]="{ group: product.D001 }" class="c-productShortList__item-link">
              <h4 class="c-productShortList__item-title c-productShortList__item-title--primary">
                <span *ngIf="generalService.debugMode"> [F001] </span>
                {{ product.F001 }}
              </h4>
              <h5 class="c-productShortList__item-title c-productShortList__item-title--secondary">
                <span *ngIf="generalService.debugMode"> [F002] </span>
                {{ product.F002 }}
              </h5>
              <div class="c-productShortList__item-details">
                <span *ngIf="generalService.debugMode"> [F004] </span>
                {{ product.F004 | number : "1.2-3" | shopCurrency }}
              </div>
            </a>
          </div>
          <div class="c-productShortList__item-addToCartContainer">
            <sv-buy-btn [data]="product" [parent]="'cart-panel'"></sv-buy-btn>
          </div>
          <div class="c-productShortList__item-price">
            <span *ngIf="generalService.debugMode"> [F004] <br /></span>
            {{
            product.price * product.quantity | number : "1.2-3" | shopCurrency
            }}
          </div>
        </div>

        <div *ngIf="
            collicoService.hasLimitedAvailProducts === true &&
            collicoService.collicoZip &&
            !collicoService.collicoAvailable
          " class="limitedAvailText">
          <span *ngIf="generalService.translationMode === true"> (C043) </span>
          {{ "CART.C043.value" | translate }}

          <!-- We are not able to deliver some of the products in your shopping
            cart to your area. Please remove the products highlighted in red
            from the shopping cart or pick a delivery address where Collico home
            delivery service is available. -->
        </div>
      </div>
    </div>
  </div>

  <div class="c-cartPanelOverlay js-toggle-cartPanel"></div>
</div>