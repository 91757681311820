import { Injectable, Inject } from "@angular/core";

import uuid from "uuid/v4";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { IModalObject } from "../ITypes";
import { DOCUMENT } from "@angular/common";
import * as moment from "moment";

interface collapsableObject {
  id: string;
  title: string;
  isCollapsed: boolean;
}

@Injectable()
export class GeneralService {
  months: string[] = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEPT",
    "OCT",
    "NOV",
    "DEC",
  ];

  zipCode: string = "";
  scrollHeight: number = 0;
  // buyCount: number = 0;

  public buyCount: BehaviorSubject<number> = new BehaviorSubject(0);
  public headerPages: BehaviorSubject<any> = new BehaviorSubject([]);
  public footerPages: BehaviorSubject<any> = new BehaviorSubject([]);

  // defaults
  private isProductTreeVisible: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private isCartPanelVisible: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private cartPanelTopHeight: number;
  private navbarHeight: BehaviorSubject<number> = new BehaviorSubject(0);

  private collapseState: collapsableObject[] = [];

  private modalState: BehaviorSubject<IModalObject> = new BehaviorSubject({
    id: "",
    name: "",
    isOpen: false,
  });

  public zipCodeObs$: BehaviorSubject<string | null> = new BehaviorSubject(
    null
  );
  public debugMode: boolean = false;
  public translationMode: boolean = false;
  public cookieConsentVisible: boolean = false;
  public zipPopUpVisible: boolean = false;
  public nearestDeliveryForPostal: any = null;
  private showNotificationBar = new BehaviorSubject<boolean>(false);
  showNotificationBar$: Observable<boolean> =
    this.showNotificationBar.asObservable();

  constructor(@Inject(DOCUMENT) private document: Document) {}

  get getDocument() {
    return this.document;
  }

  get observeHeaderPages(): Observable<any> {
    return this.headerPages.asObservable();
  }
  set setHeaderPages(value: any) {
    this.headerPages.next(value);
  }

  get observeFooterPages(): Observable<any> {
    return this.footerPages.asObservable();
  }
  set setFooterPages(value: any) {
    this.footerPages.next(value);
  }

  // product tree getter setter
  set setProductTreeState(value: boolean) {
    this.isProductTreeVisible.next(value);
  }

  get getProductTreeState(): boolean {
    return this.isProductTreeVisible.getValue();
  }

  get observeProductTreeState(): Observable<boolean> {
    return this.isProductTreeVisible.asObservable();
  }

  // cart panel state getter setter
  set setCartPanelState(value: boolean) {
    this.isCartPanelVisible.next(value);
  }

  get getCartPanelState(): boolean {
    return this.isCartPanelVisible.getValue();
  }

  get getCollapsableState(): collapsableObject[] {
    return this.collapseState;
  }

  set setCollapsableState(value: collapsableObject) {
    let foundInState = this.collapseState.find((e) => e.id === value.id);

    if (foundInState) {
      this.collapseState.forEach((a) => {
        if (a.id === value.id) {
          a.isCollapsed = !a.isCollapsed;
        }
      });
    } else {
      this.collapseState.push({ ...value, isCollapsed: !value.isCollapsed });
    }
  }

  get observeCartPanelState(): Observable<boolean> {
    return this.isCartPanelVisible.asObservable();
  }

  // navbar height getter setter
  set setNavbarHeight(value: number) {
    this.navbarHeight.next(value);
  }

  get getNavbarHeight(): number {
    return this.navbarHeight.getValue();
  }

  get observeNavbarHeight(): Observable<number> {
    return this.navbarHeight.asObservable();
  }

  get observeBuyCount(): Observable<number> {
    return this.buyCount.asObservable();
  }

  // cart panel height getter setter
  set setCartPanelTopHeight(value: number) {
    this.cartPanelTopHeight = value;
  }

  get getCartPanelTopHeight() {
    return this.cartPanelTopHeight;
  }

  set setZipCodeObs(value: string) {
    this.zipCodeObs$.next(value);
  }

  setShowNotificationBar(value: boolean) {
    this.showNotificationBar.next(value);
  }

  // commons
  generateURL(url: string) {
    if (url) {
      let modifiedUrl: string = url + "";
      modifiedUrl = modifiedUrl.toLowerCase().replace(/\s/g, "-");
      modifiedUrl = modifiedUrl.toLowerCase().replace(/\//g, "-");
      modifiedUrl = modifiedUrl.toLowerCase().replace(/\?/g, "-");
      modifiedUrl = modifiedUrl.toLowerCase().replace(/\&/g, "-");
      // modifiedUrl = modifiedUrl.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "-");

      return modifiedUrl;
    }
  }

  sumTotalItems(products) {
    return products.reduce((a: number, b) => Number(a) + Number(b.quantity), 0);
  }

  getFullMonth(month: number) {
    if (month === null || month === undefined) {
      return "N/A";
    }
    return this.months[month - 1];
  }

  get generateUUID() {
    return uuid();
  }

  //  ------------- modal -------------------
  set setModalState(value: IModalObject) {
    this.modalState.next(value);
  }

  get getModalState(): IModalObject {
    return this.modalState.getValue();
  }

  get observeModalState(): Observable<IModalObject> {
    return this.modalState.asObservable();
  }
  //  ------------- modal -------------------

  // --------------- images resize ------------------
  resizeImage(url?: string, size?: string, ext?: string) {
    try {
      if (!url) {
        return;
      }

      if (!size) {
        size = "-xs";
      }

      const urlExt = url.split(".").pop();
      const thumbExt = ext ? ext : `${size}.${urlExt}`;
      return url.replace(/\.[^.]+$/, thumbExt);
    } catch (error) {
      return url;
    }
  }
  // --------------- images resize ------------------

  activateDebugMode() {
    this.debugMode = true;
  }

  activateTranslationMode() {
    this.translationMode = true;
  }

  toggleCookieConsent(consent: boolean) {
    this.cookieConsentVisible = consent;
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getNearestDeliveryForPostal() {
    let currentDate = new Date();

    let day = currentDate.getDay(); // Sunday is 0, Monday is 1
    let hours = currentDate.getHours();

    if (hours >= 0 && hours <= 2) {
      currentDate.setDate(currentDate.getDate() + 1);
    } else {
      currentDate.setDate(currentDate.getDate() + 2);
    }

    day = currentDate.getDay();

    if (day === 0) {
      currentDate.setDate(currentDate.getDate() + 2);
    } else if (day === 1) {
      currentDate.setDate(currentDate.getDate() + 1);
    }

    this.nearestDeliveryForPostal = currentDate;
    return currentDate;
  }

  makeDateString(date) {
    if (typeof date === "string") {
      date = date.replace(/ /g, "T");
    }

    let dateUTC = new Date(date);

    let dd = dateUTC.getDate();
    let mm = dateUTC.getMonth() + 1; // 0 is January, so we must add 1
    let yyyy = dateUTC.getFullYear();

    let dayDigits = dd.toString().length;
    let d = "";

    if (dayDigits === 1) {
      d = "0" + dd;
    } else {
      d = "" + dd;
    }

    let monthDigits = mm.toString().length;
    let m = "";

    if (monthDigits === 1) {
      m = "0" + mm;
    } else {
      m = "" + mm;
    }

    const dateString = yyyy + "-" + m + "-" + d;

    return dateString;
  }

  parseAmount(amount: number, dp = 0, splitBy: string = ",") {
    if (!amount) {
      amount = 0;
    }
    let _amount = Number(amount).toFixed(dp);
    return _amount.replace(".", splitBy);
  }

  toNumber(amount: number | string) {
    if (!amount) {
      amount = 0;
    }
    if (typeof amount == "string") {
      amount = Number(amount.replace(",", "."));

      if (isNaN(amount)) {
        amount = 0;
      }
    }
    return amount;
  }

  parseQty(amount: number, dp = 0, splitBy: string = ",") {
    if (!amount) {
      return "-";
    }
    let _amount = parseFloat(Number(amount).toFixed(dp)).toString();
    return _amount.replace(".", splitBy);
  }

  getFormattedDate(str, format) {
    return moment(str).format(format);
  }

  setWidthVariableInStyle() {
    let document = this.getDocument;
    let vw = document.documentElement.clientWidth / 100;
    document.documentElement.style.setProperty("--vw", vw + "px");
  }

  changeBuyCountChange(count: number) {
    this.buyCount.next(count);
  }

  isEmptyObject(obj: any) {
    return Object.keys(obj).length === 0;
  }

  removeEmptyPropertiesFromObject(obj) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null)
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }
}
