export class Command {
  static CLOSE_OTHER_COUNTERS = 'CLOSE_OTHER_COUNTERS';
  static IS_USING_KEYBOARD = 'IS_USING_KEYBOARD';

  private _type: string;
  private _commandData: any;
  private _extraData: any;

  constructor(type: string, commandData: any, extraData = {}) {
    this._type = type;
    this._commandData = commandData;
    this._extraData = extraData;
  }

  public isType(checkType: string) {
    return checkType === this._type;
  }

  get commandData(): any {
    return this._commandData;
  }

  get type(): string {
    return this._type;
  }
}
