import { Injectable } from "@angular/core";
import { CacheService, ApiService } from "src/app/core/services";
import { IUserProfileRequest } from "src/app/core/ITypes";
import { GeneralService } from "./general.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserService {
  walletBalance: number = 0;
  refundObj: any = null;
  shippingAddress: any = [];
  defaultShippingAddress: any = null;
  lastOrder: any = null;
  editedLastOrderAddress: any = null;

  loadingWallet: boolean = false;
  loadingRefund: boolean = false;
  loadingAddresses: boolean = false;
  loadingLastOrder: boolean = false;

  constructor(
    private apiService: ApiService,
    private cacheService: CacheService,
    private generalService: GeneralService
  ) {}

  get getUserUUID() {
    // return this.cookieService.get("UUTID");
    return this.cacheService.get("UUTID");
  }

  set setUserUUID(id: string) {
    // this.cookieService.set("UUTID", id);
    this.cacheService.set("UUTID", id);
  }

  // handling wallet here..

  async getWallet() {
    try {
      this.loadingWallet = true;
      const { status_code, result } = await this.apiService
        .get("/wallet")
        .toPromise();

      if (status_code === 200 && result) {
        this.walletBalance = result.total;
        this.loadingWallet = false;

        return result;
        // return result.total;
      }
      return 0;
    } catch (error) {
      throw error;
    }
  }

  async refundBalance(params?: any) {
    try {
      this.loadingRefund = true;
      const { status_code, result } = await this.apiService
        .post(params, "/wallet/refund")
        .toPromise();

      if (status_code === 200 && result) {
        this.refundObj = result;
        this.loadingRefund = false;

        return result;
      }
      return [];
    } catch (error) {
      throw error;
    }
  }

  async getShippingAddresses() {
    let user_uuid = this.getUserUUID;
    this.loadingAddresses = true;
    try {
      const { status_code, result } = await this.apiService
        .get("/user/shipping-address?user_uuid=" + user_uuid)
        .toPromise();

      if (status_code && status_code === 200 && result) {
        this.shippingAddress = result as any;
        this.loadingAddresses = false;

        return result;
      }
      return {};
    } catch (error) {
      this.loadingAddresses = false;
      throw error;
    }
  }

  async getBillingAddresses() {
    let user_uuid = this.getUserUUID;
    this.loadingAddresses = true;
    try {
      const { status_code, result } = await this.apiService
        .get("/user/billing-address?user_uuid=" + user_uuid)
        .toPromise();

      if (status_code && status_code === 200 && result) {
        return result;
      }
    } catch (error) {
      this.loadingAddresses = false;
      throw error;
    }
  }

  async addNewAddress(newAddress) {
    newAddress.user_uuid = this.getUserUUID;

    try {
      const { status_code, result } = await this.apiService
        .post(newAddress, "/user/shipping-address")
        .toPromise();

      if (status_code && status_code === 200 && result) {
        // this.addresses = result as any;
        this.generalService.setModalState = {
          id: "deliveryAddressModal",
          name: "deliveryAddress",
          isOpen: false,
        };
        await this.getShippingAddresses();
        return result;
      }
      return {};
    } catch (error) {
      throw error;
    }
  }

  async updateAddress(address, updatedAddress?: any) {
    try {
      // address.is_default = 1
      const { status_code, result } = await this.apiService
        .put(updatedAddress, "/user/shipping-address/" + address.id)
        .toPromise();

      await this.getShippingAddresses();

      // if (status_code && status_code === 200 && result) {
      // //
      // //   this.addresses = result as any;
      // //   return result;

      //   return result;
      // }
      return {};
    } catch (error) {
      throw error;
    }
  }

  async getLastOrder() {
    let user_uuid = this.getUserUUID;
    this.loadingAddresses = true;
    // this.shippingAddress = [];
    try {
      const { status_code, result } = await this.apiService
        .get("/order/last-order?user_uuid=" + user_uuid)
        .toPromise();

      if (status_code && status_code === 200 && result) {
        this.lastOrder = result as any;
        this.loadingLastOrder = false;
        this.updateLastOrderAddress();
        return result;
      }
      return {};
    } catch (error) {
      this.loadingLastOrder = false;
      throw error;
    }
  }

  async updateLastOrderAddress() {
    if (this.lastOrder) {
      let pickedAddress: any = (({
        additional_phone,
        delivery_notes,
        door_code,
        shipping_city,
        shipping_country,
        shipping_email,
        shipping_firstname,
        shipping_lastname,
        shipping_phone,
        shipping_street,
        shipping_zip,
        user_uuid,
        order_number,
      }) => ({
        additional_phone,
        delivery_notes,
        door_code,
        shipping_city,
        shipping_country,
        shipping_email,
        shipping_firstname,
        shipping_lastname,
        shipping_phone,
        shipping_street,
        shipping_zip,
        user_uuid,
        order_number,
      }))(this.lastOrder);

      pickedAddress.madeFromOrder = true;
      pickedAddress.id = "madeFromOrder";
      this.editedLastOrderAddress = pickedAddress;
      return this.editedLastOrderAddress;
    }
  }

  async updateProfile(payload: IUserProfileRequest) {
    try {
      const { status_code, result } = await this.apiService
        .patch(payload, `/user`)
        .toPromise();

      if (status_code && status_code === 200 && result) {
        return result;
      }
      return {};
    } catch (error) {
      throw error;
    }
  }

  async getUserShoppingMode() {
    try {
      const checkoutInfo = this.cacheService.get("checkout-info");
      const cart = this.cacheService.get("cart");
      const userShippingInfo = await this.cacheService.get(
        "user-shipping-info"
      );
      const deliveryDate = checkoutInfo?.collico?.date;
      const zipCode = userShippingInfo?.user_info?.zip_code;
      const { status_code, result } = await this.apiService
        .get("/user/shopping_mode", {
          user_uuid: this.getUserUUID,
          delivery_date: deliveryDate,
          cart_id: cart && cart.id,
          zip_code: zipCode,
          // mode: "dev",
          // time: "12:59",
          // fast_cutoff_time: "13:00",
        })
        .toPromise();

      if (status_code === 200 && result) {
        return result;
      }
      return {};
    } catch (error) {
      throw error;
    }
  }
}
