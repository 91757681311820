import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Renderer2,
  HostListener,
} from "@angular/core";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { IUser } from "../../core/ITypes";
import {
  CartService,
  SiteConfigService,
  GlobalEventService,
} from "src/app/core/services";
import { GeneralService } from "../services/general.service";
import { AuthService } from "src/app/core/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import * as e from "express";
import { Router } from "@angular/router";
import { CollicoService } from "../services/collico.service";

@Component({
  selector: "sv-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("svNavbar") elementView: ElementRef;
  private unsubscribe$ = new Subject<void>();
  title: string = "";
  user: IUser;
  loggedIn: boolean = false;
  userDropdown: boolean = false;
  isRecipeVisible: boolean = false;
  isDashboardVisible: boolean = false;
  isAccountVisible: boolean = false;
  isAddressesVisible: boolean = false;
  isRefundsVisible: boolean = false;
  isOrdersVisible: boolean = false;
  cart = { id: 0, products: [], total: 0 };
  cartTotal: number = 0;

  isMenuItemsVisible: boolean = false;
  logo = {
    src: "",
    label: "",
  };

  menuPages: any = [];
  staticHeaderPages: any[] = [];

  searchBarVisible: boolean = false;

  userDropdownListener: () => void;

  constructor(
    private authService: AuthService,
    public generalService: GeneralService,
    private renderer: Renderer2,
    private siteConfigService: SiteConfigService,
    private translate: TranslateService,
    private cartService: CartService,
    private router: Router,
    private globalEventService: GlobalEventService,
    private collicoService: CollicoService
  ) {
    const { name, navbar, dashboard } = this.siteConfigService.siteConfig;
    this.title = name;
    this.logo = navbar.logo;
    this.isMenuItemsVisible = navbar.menu.visible;
    this.menuPages = navbar.menu.menuPages;
    this.isRecipeVisible = navbar.recipe.visible;

    this.isDashboardVisible = dashboard.visible;
    this.isAccountVisible = dashboard.profile.visible;
    this.isRefundsVisible = dashboard.refunds.visible;
    this.isAddressesVisible = dashboard.addresses.visible;
    this.isOrdersVisible = dashboard.orders.visible;

    this.cart = this.cartService.cartData || this.cart;

    //For outside the application
    this.globalEventService.subscribe("angular.signIn", this.signInModal, this);
    this.globalEventService.subscribe("angular.signUp", this.signUpModal, this);
  }

  ngOnInit() {
    // auth service logged in listener
    this.authService.isLoggedIn
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loggedIn) => {
        this.loggedIn = loggedIn;
      });

    // auth service user change listener
    this.authService.observeUser
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        this.user = user;
      });

    // this.cartService.observeCart
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe((data) => {
    //     if (data) {
    //       this.cart = JSON.parse(data);
    //       //this.cartService.setProductsTotal(this.cart.products);
    //       this.cartService.observeProductsTotal
    //         .pipe(takeUntil(this.unsubscribe$))
    //         .subscribe((total) => {
    //           this.cartTotal = total;
    //         });
    //       if (!this.cart.products.length || this.cart.products.length === 0) {
    //         this.cartTotal = 0;
    //       }
    //     } else {
    //       this.cartTotal = 0;
    //       this.cart = { id: 0, products: [], total: 0 };
    //     }
    //   });

    this.cartService.cartDataObs$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((cartData) => {
        if (cartData) {
          const { products, total } = cartData;
          this.cart.products = products;
          this.cartTotal = total;
        }
      });

    this.generalService.headerPages
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((headerPages) => {
        this.staticHeaderPages = headerPages;
      });
  }

  toggleSearchBar() {
    this.generalService.zipPopUpVisible = false;

    let document = this.generalService.getDocument;
    let searchBar = document.querySelector(
      ".c-headerToolsContainer"
    ) as HTMLElement;
    let navBar = document.querySelector(
      ".c-headerNavbarContainer"
    ) as HTMLElement;

    if (this.searchBarVisible === true) {
      searchBar.style.position = "relative";
      searchBar.style.top = "0px";
      searchBar.style.animationName = "";
    } else {
      searchBar.style.display = "block";
      searchBar.style.top = navBar.clientHeight + "px";
      // searchBar.style.position = "-webkit-sticky";
      searchBar.style.position = "sticky";

      searchBar.style.animationName = "headerTools--appear";
      searchBar.style.animationDuration = ".4s";
      searchBar.style.animationTimingFunction = "ease-out";
    }

    this.searchBarVisible = !this.searchBarVisible;
  }

  toggleZip() {
    let document = this.generalService.getDocument;
    let zipDropDown = document.querySelector(
      ".c-dropdown__options"
    ) as HTMLElement;
    let navBar = document.querySelector(
      ".c-headerNavbarContainer"
    ) as HTMLElement;

    let isCartRoute = this.router.url.search("/cart");
    if (this.collicoService.collicoZip) {
      if (isCartRoute === -1) {
        this.router.navigate(["/cart"]);
      } else {
        let document = this.generalService.getDocument;
        let productSection = document.getElementById("address-block");
        if (productSection) {
          productSection.scrollIntoView({ behavior: "smooth" });
        }
      }
    } else if (this.generalService.zipPopUpVisible === true) {
      this.generalService.zipPopUpVisible = false;
      zipDropDown.style.top = "100%";
      zipDropDown.style.position = "absolute";
    } else {
      this.generalService.zipPopUpVisible = true;
      zipDropDown.style.top = navBar.clientHeight - 5 + "px";
      zipDropDown.style.position = "fixed";
    }
  }

  ngAfterViewInit() {
    let document = this.generalService.getDocument;
    let navBar = document.querySelector(
      ".c-headerNavbarContainer"
    ) as HTMLElement;

    const navbarHeight = navBar.offsetHeight;
    this.generalService.setNavbarHeight = navbarHeight;
    this.generalService.setCartPanelTopHeight = navbarHeight;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  signUpModal() {
    this.generalService.setCartPanelState = false;
    this.generalService.setModalState = {
      id: "1",
      name: "signUpModal",
      isOpen: true,
    };
  }

  signInModal() {
    this.generalService.setCartPanelState = false;
    this.generalService.setModalState = {
      id: "2",
      name: "signInModal",
      isOpen: true,
    };
  }

  signOutModal() {
    this.generalService.setCartPanelState = false;
    this.generalService.setModalState = {
      id: "3",
      name: "signOutModal",
      isOpen: true,
    };
  }

  handleToggleProductTree() {
    this.generalService.setProductTreeState =
      !this.generalService.getProductTreeState;
  }

  handleToggleCartPanel() {
    let isCartRoute = this.router.url.search("/cart");

    if (isCartRoute === -1) {
      this.generalService.setCartPanelState =
        !this.generalService.getCartPanelState;
    } else {
      let document = this.generalService.getDocument;
      let productSection = document.getElementById("cart-page");
      productSection.scrollIntoView({ behavior: "smooth" });
    }
  }

  userDropdownToggle() {
    // this.userDropdown = !this.userDropdown;

    if (this.userDropdown === true) {
      this.userDropdown = false;
      this.userDropdownListener();
      // ^ this is angular's Render2 unlisten function, same as window.removeEventListener
    } else {
      this.userDropdown = true;
      this.userDropdownListener = this.renderer.listen(
        "window",
        "click",
        (e: MouseEvent) => {
          let div = e.target as HTMLElement;
          if (div.className.search("user-ignore") === -1) {
            this.userDropdownToggle();
          }
        }
      );
    }
  }

  togglePageMobileDropdown() {
    const document = this.generalService.getDocument;
    const bodyElement = document.body;
    const styleClass = "is-headerMenu--isVisible";

    if (bodyElement.className.search(styleClass) === -1) {
      this.renderer.addClass(bodyElement, styleClass);
    } else {
      this.renderer.removeClass(bodyElement, styleClass);
    }
  }

  @HostListener("document:click", ["$event.target"])
  public onClick(targetElement) {
    const document = this.generalService.getDocument;
    const bodyElement = document.body;
    const listenedElementSelector = ".js-toggle-headerMenu";
    const styleClass = "is-headerMenu--isVisible";

    if (!targetElement.closest(listenedElementSelector)) {
      this.renderer.removeClass(bodyElement, styleClass);
    }
  }

  changeLanguage(lang?: string) {
    this.translate.use(lang);
  }
}
