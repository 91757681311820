import { Component, OnInit, Input } from "@angular/core";

import { IProduct } from "./IProduct";
import { GeneralService } from "../services/general.service";
import { SiteConfigService } from "src/app/core/services";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "sv-product-card",
  templateUrl: "./product-card.component.html",
  styleUrls: ["./product-card.component.scss"],
})
export class ProductCardComponent implements OnInit {
  // defaults
  @Input() data: IProduct = null;
  @Input() featured: boolean = false;
  @Input() loggedIn: boolean = false;
  price: string;
  subprice: string;
  generateURL: (url: string) => string;

  private unsubscribe$ = new Subject<void>();

  filtersParams: string = "";

  constructor(
    public generalService: GeneralService,
    private siteConfigService: SiteConfigService,
    private route: ActivatedRoute
  ) {
    this.generateURL = generalService.generateURL;
  }

  async ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (params) => {
        if (params.filters) {
          this.filtersParams = params.filters;
        }
      });

    let price: string | string[] = this.data.F018 + "";
    price = price.split(".");
    this.price = price[0];
    this.subprice = price[1];
    if (this.subprice && this.subprice.length === 1) {
      this.subprice += "0";
    }
  }
}
