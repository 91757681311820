import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IQuery } from "src/app/core/ITypes";
import {
  ApiService,
  CacheService,
  CentralStorageService,
} from "src/app/core/services";
import { GeneralService } from "./general.service";
import { ShopService } from "./shop.service";
import { UserService } from "./user.service";
import { map } from "rxjs/operators";
import {
  IDeliveryAvailability,
  IDeliveryAvailabilityAndDates,
  IDeliveryDate,
} from "src/app/layout/cart/ITypes";

@Injectable({
  providedIn: "root",
})
export class CollicoService {
  collicoAvailable: boolean = false;
  deliveryAvailable: boolean = true;
  hasLimitedAvailProducts: boolean = false;
  limitedAvailabilityProductsCount: number = 0;
  collicoZip: string = null;
  tempCollicoZip: string = null;
  products: any[] = null;
  selectedCollicoZone: any = null;
  selectedDeliverMehtod: any = null;
  nonDeliverableDates: any[] = [];
  nearestCollicoDeliveryDateAndTime: any = null;
  nearestAvailableTime: any = null;
  preSelectedDeliveryDate: Date = null;

  private unsubscribe$ = new Subject<void>();
  private collicoZipChanged = new BehaviorSubject<string>(null);
  private tempCollicoZipChanged = new BehaviorSubject<string>(null);
  private collicoDeliveryDateAndTimeChanged = new BehaviorSubject<string>(null);

  // Expose an observable that others can subscribe to
  collicoZipChanged$ = this.collicoZipChanged.asObservable();
  tempCollicoZipChanged$ = this.tempCollicoZipChanged.asObservable();
  collicoDeliveryDateAndTimeChanged$ =
    this.collicoDeliveryDateAndTimeChanged.asObservable();

  constructor(
    private shopService: ShopService,
    private apiService: ApiService,
    private cacheService: CacheService,
    private generalService: GeneralService,
    private api: ApiService,
    private centralStorageService: CentralStorageService,
    private userService: UserService
  ) {
    this.shopService.cartData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((tempCart) => {
        if (tempCart) {
          this.products = tempCart.products;
        }
      });
  }

  setCollicoZip(zip: string) {
    this.collicoZip = zip;
    this.collicoZipChanged.next(zip);
  }

  setTempCollicoZip(zip: string) {
    this.tempCollicoZip = zip;
    this.tempCollicoZipChanged.next(zip);
  }

  setCollicoDeliveryDateAndTime(dateAndTimeObj: any) {
    this.nearestCollicoDeliveryDateAndTime = dateAndTimeObj;
    this.collicoDeliveryDateAndTimeChanged.next(dateAndTimeObj);
  }

  checkLimitedAvailability(products?: any) {
    // if (!products || !products.length) {
    //   this.products = products;
    // }
    this.products = products;

    if (this.products && this.products.length) {
      let limitedAvailProds = this.products.filter(
        (p) => p.F020 && p.F020 === true
      );

      if (limitedAvailProds && limitedAvailProds.length) {
        this.hasLimitedAvailProducts = true;
        this.limitedAvailabilityProductsCount = limitedAvailProds.length;

        return true;
      } else {
        this.hasLimitedAvailProducts = false;
        this.limitedAvailabilityProductsCount = 0;
      }

      //this.checkCollicoAvailable();
    } else {
      this.hasLimitedAvailProducts = false;
      this.limitedAvailabilityProductsCount = 0;
    }
    return false;
  }

  checkCollicoAvailable() {
    if (this.selectedCollicoZone) {
      this.collicoAvailable = true;
    } else {
      this.collicoAvailable = false;
    }
  }

  checkNonDeliverableDates(incomingDates) {
    let temp = incomingDates;

    if (!this.nonDeliverableDates.length) {
      this.getNonDeliveryDates();
    }

    if (incomingDates.length && this.nonDeliverableDates.length) {
      temp = [];
      for (let index = 0; index < incomingDates.length; index++) {
        const d = incomingDates[index];

        const found = this.nonDeliverableDates.find(
          (element) => element === d.date
        );

        if (found === undefined) {
          temp.push(d);
        }
      }
    }

    return temp;
  }

  async getNonDeliveryDates() {
    try {
      const { status_code, result } = await this.apiService
        .get("/no_delivery_dates")
        .toPromise();

      if (status_code && status_code === 200 && result) {
        let arr = [];
        let res: any = result;

        if (res && res.length) {
          for (let index = 0; index < res.length; index++) {
            const element = res[index];
            arr.push(element.no_delivery_date);
          }

          if (arr.length) {
            this.nonDeliverableDates = arr;
            return arr;
          }
          return [];
        }

        // return result;
      }
      return {};
    } catch (error) {
      throw error;
    }
  }

  getDeliveryDatesNew(
    query?: IQuery
  ): Observable<IDeliveryAvailabilityAndDates> {
    const cart = this.cacheService.get("cart");
    const iQuery = {
      ...query,
      cart_id: cart?.id || null,
      user_uuid: this.userService.getUserUUID,
    };

    return this.api
      .get("/delivery_dates", iQuery)
      .pipe(map((response) => response.result));
  }

  getDeliveryAvailability(query?: IQuery): Observable<IDeliveryAvailability> {
    return this.api
      .get("/delivery_availability", query)
      .pipe(map((response) => response.result));
  }

  getZipCodeFromStorage() {
    const storedUserShippingInfo = this.cacheService.get("user-shipping-info");
    const { user_info } = storedUserShippingInfo;
    if (user_info && user_info.zip_code) {
      return user_info.zip_code;
    }
    return null;
  }

  async getCollicoDeliveryDate(
    deliveryDates: IDeliveryDate[],
    deliveryDate: string
  ): Promise<IDeliveryDate> {
    const storedUserShippingInfo = this.cacheService.get("user-shipping-info");
    const { user_info } = storedUserShippingInfo;
    const zipCode = this.collicoZip
      ? this.collicoZip
      : user_info && user_info.zip_code
      ? user_info.zip_code
      : null;

    let deliveryDatesI =
      deliveryDates.length === 0
        ? (await this.fetchDeliveryDates(zipCode)).delivery_dates
        : [...deliveryDates];

    const collicoDeliveryDateInfo =
      deliveryDatesI.find((x) => x.date === deliveryDate) ||
      deliveryDatesI.find(Boolean);

    return collicoDeliveryDateInfo;
  }

  private fetchDeliveryDates(zipCode: string): Promise<any> {
    return new Promise((resolve) => {
      this.getDeliveryDatesNew({ zip_code: zipCode }).subscribe(
        (deliveryDates) => {
          resolve(deliveryDates);
        },
        (error) => {
          console.error("Error fetching delivery dates:", error);
          resolve({ delivery_dates: [] }); // Resolve with an empty array in case of an error
        }
      );
    });
  }

  async updateNearestCollicoDeliveryDate() {
    const storedCheckoutInfo = this.cacheService.get("checkout-info");
    const storedUserShippingInfo = this.cacheService.get("user-shipping-info");
    const { collico } = storedCheckoutInfo;
    const { user_info } = storedUserShippingInfo;
    if (user_info.zip_code) {
      let zipCodeFromStorage = user_info.zip_code;

      this.getDeliveryAvailability({
        zip_code: zipCodeFromStorage,
      }).subscribe(async (deliveryAvailability) => {
        const {
          collico_available,
          delivery_available,
          pickup_available,
          valid_zipcode,
        } = deliveryAvailability;

        if (collico_available || delivery_available) {
          this.collicoAvailable = collico_available;
          this.deliveryAvailable = delivery_available;
          //Todo: should be removed when refactor
          if (pickup_available) {
            this.collicoAvailable = true;
          }
          if (valid_zipcode) {
            zipCodeFromStorage = valid_zipcode;
          }
          this.setCollicoZip(zipCodeFromStorage);

          if (collico_available || pickup_available) {
            this.getDeliveryDatesNew({
              zip_code: zipCodeFromStorage,
            }).subscribe(async (deliveryDates) => {
              const { delivery_dates } = deliveryDates;
              let nearestCollicoDeliveryDateInfo: IDeliveryDate =
                delivery_dates.find(Boolean);

              if (nearestCollicoDeliveryDateInfo) {
                const { date: storedCollicoDate } = collico;
                const { date: nearestCollicoDate } =
                  nearestCollicoDeliveryDateInfo;
                const nearestCollicoDateParse = Date.parse(nearestCollicoDate);
                const storedCollicoDateParse = Date.parse(storedCollicoDate);

                if (storedCollicoDateParse >= nearestCollicoDateParse) {
                  nearestCollicoDeliveryDateInfo =
                    await this.getCollicoDeliveryDate(
                      delivery_dates,
                      storedCollicoDate
                    );
                } else {
                  const { date, default_start, default_end, start, end } =
                    nearestCollicoDeliveryDateInfo;
                  const collicoObj = {
                    date,
                    timeFrom: default_start,
                    timeTo: default_end,
                    timeStart: start,
                    timeEnd: end,
                    zoneId: null,
                    zoneName: null,
                  };
                  this.centralStorageService.setCheckoutInfoByCheckoutPage(
                    "collico",
                    collicoObj
                  );
                }

                //Store to Service
                this.setCollicoDeliveryDateAndTime(
                  nearestCollicoDeliveryDateInfo
                );
              }
            });
          }
        }
      });
    }
  }

  handlePreSelectedDeliveryDate(
    requestedDate: Date | null,
    deliveryDateRange: IDeliveryDate[]
  ): IDeliveryDate | null {
    // Validate input
    if (
      !requestedDate ||
      !Array.isArray(deliveryDateRange) ||
      deliveryDateRange.length === 0
    ) {
      return null;
    }

    // Normalize dates to midnight for consistent comparison
    const normalizeDate = (date: Date | string): Date => {
      const normalizedDate = new Date(date);
      normalizedDate.setHours(0, 0, 0, 0);
      return normalizedDate;
    };

    const today = normalizeDate(new Date());
    const requestedDateObj = normalizeDate(requestedDate);

    // Check if the requested date is in the past
    if (requestedDateObj < today) {
      return null;
    }

    // Convert delivery date range to normalized dates for efficient comparison
    const normalizedDeliveryDates = deliveryDateRange.map((item) => ({
      ...item,
      normalizedDate: normalizeDate(item.date),
    }));

    // Check if the requested date is exactly in the allowed range
    const exactMatch = normalizedDeliveryDates.find(
      (item) => item.normalizedDate.getTime() === requestedDateObj.getTime()
    );

    if (exactMatch) {
      return exactMatch;
    }

    // Find the next available date in the allowed range
    const nextAvailableDate = normalizedDeliveryDates
      .filter((item) => item.normalizedDate > requestedDateObj)
      .sort(
        (a, b) => a.normalizedDate.getTime() - b.normalizedDate.getTime()
      )[0];

    return nextAvailableDate;
  }
}
