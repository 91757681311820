import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { productsTree } from "src/app/mockdata";
import { GeneralService } from "../services/general.service";
import { DisplayGroupService } from "../services/display-group.service";
import { Router, ActivatedRoute } from "@angular/router";
import { CentralStorageService } from "src/app/core/services";
import { ProductService, VendorService } from "../services";
import { EDisplayType } from "src/app/layout/vendor/ETypes";
import { IVendor } from "src/app/layout/vendor/ITypes";
import { IDisplayGroups } from "src/app/core/ITypes";

@Component({
  selector: "sv-product-tree",
  templateUrl: "./product-tree.component.html",
  styleUrls: ["./product-tree.component.scss"],
})
export class ProductTreeComponent implements OnInit, OnDestroy {
  EDisplayType = EDisplayType;

  // defaults
  navbarHeight: string;
  categories = productsTree;
  isProductTreeTouched = false;
  options = {};
  staticHeaderPages: any = [];
  displayGroups: IDisplayGroups[] = [];
  selectedChildGroup: string = "";
  selectedParentGroup: string = "";
  filtersParams: string = "";
  vendors: IVendor[] = [];
  vendor: IVendor = null;
  displayType: EDisplayType = EDisplayType.Display_Groups;

  private unsubscribe$ = new Subject<void>();

  constructor(
    public translate: TranslateService,
    public generalService: GeneralService,
    private displayGroupService: DisplayGroupService,
    public route: ActivatedRoute,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private vendorService: VendorService,
    private centralStorageService: CentralStorageService,
    private productService: ProductService
  ) {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((param) => {
        this.selectedChildGroup = null;

        if (param.filters) {
          this.filtersParams = param.filters;
        } else {
          this.filtersParams = "";
        }

        if (param.group) {
          this.selectedParentGroup = param.group;
        }
        if (param.subGroup) {
          this.selectedChildGroup = param.subGroup;
        }
      });

    this.generalService.headerPages
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((headerPages) => {
        this.staticHeaderPages = headerPages;
      });
  }

  async ngOnInit() {
    this.generalService.observeNavbarHeight
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((height) => {
        let nav = document.getElementById("navBar");
        if (nav.offsetWidth >= 680) {
          this.navbarHeight = height > 0 ? `${nav.offsetHeight}px` : "0";
        } else {
          this.navbarHeight = nav.offsetHeight + "px";
        }
      });

    // Subscribe to vendors changes
    this.vendorService.vendorsObs$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vendors) => {
        this.vendors = vendors;
      });

    // Subscribe to vendor change
    this.vendorService.vendorObs$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vendor) => {
        this.vendor = vendor;
      });

    // Subscribe to display group change
    this.displayGroupService.displayGroupsObs$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (displayGroups) => {
        if (displayGroups) {
          this.displayGroups = [];
          this.displayGroups = displayGroups;
        }
      });
  }

  /**
   * unsubscribe to observables
   * @name ngOnDestroy
   * @memberof CartPanelComponent
   */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleCloseProductTree() {
    this.generalService.setProductTreeState = false;
  }

  handleToggleProductTreeItem(idx: number) {
    this.isProductTreeTouched = true;
    this.categories.map((cat, i) => {
      cat.visible = false;
      if (i === idx) {
        cat.visible = true;
      }
    });
  }

  selectParentGroup(group: string) {
    this.selectedParentGroup = group;
  }

  handleCollapse(group: any) {
    group.expanded ? (group.expanded = false) : (group.expanded = true);
    this.displayGroups.forEach((g) => {
      if (g.code !== group.code && g.expanded === true) {
        g.expanded = !g.expanded;
      }
    });

    if (!group.display_groups || !group.display_groups.length) {
      this.generalService.setProductTreeState = false;
    }
  }

  subGroupClicked() {
    this.generalService.setProductTreeState = false;
  }

  async selectVendorTag(vendor: IVendor) {
    const currentUrl = this.router.url;
    let queryParams = {};

    this.vendorService.setVendor(vendor);
    const displayGroups = await this.displayGroupService.getDisplayGroups();
    this.generalService.setProductTreeState = false;

    if (currentUrl === "/") {
      queryParams = { only_vendor_products: true };
      this.router.navigate(["/p/search"], { queryParams });
    } else if (currentUrl.startsWith("/p/search")) {
      if (this.selectedChildGroup) {
        const selectedChildGroupInfo = displayGroups
          .find((x) => x.code === this.selectedParentGroup)
          .display_groups.find(
            (y) => y.code === this.selectedChildGroup && y.has_vendor === true
          );

        // If selected child group have the choosen vendor products
        if (selectedChildGroupInfo) {
          this.productService.setRefetchProductListing();
        } else {
          // Otherwise check if parent group have the choosen vendor products
          const parentGroupInfo = displayGroups.find(
            (x) => x.code === this.selectedParentGroup && x.has_vendor === true
          );

          if (parentGroupInfo) {
            queryParams = {
              group: this.selectedParentGroup,
              filters: this.filtersParams,
            };
          } else {
            // Otherwise redirect to choosen vendor products
            queryParams = {
              only_vendor_products: true,
              filters: this.filtersParams,
            };
          }
          this.router.navigate(["/p/search"], { queryParams });
        }
      } else if (this.selectedParentGroup) {
        // If selected parent group have the choosen vendor products
        const selectedParentGroupInfo = displayGroups.find(
          (x) => x.code === this.selectedParentGroup && x.has_vendor === true
        );

        if (selectedParentGroupInfo) {
          this.productService.setRefetchProductListing();
        } else {
          // Otherwise redirect to choosen vendor products
          queryParams = {
            only_vendor_products: true,
            filters: this.filtersParams,
          };
          this.router.navigate(["/p/search"], { queryParams });
        }
      }
    } else if (currentUrl.startsWith("/p/")) {
      const findParentGroup = this.findParentGroup(
        displayGroups,
        this.selectedChildGroup
      );
      if (findParentGroup) {
        queryParams = {
          group: findParentGroup.code,
          subGroup: this.selectedChildGroup,
          filters: this.filtersParams,
        };
      } else {
        queryParams = {
          only_vendor_products: true,
          filters: this.filtersParams,
        };
      }
      this.router.navigate(["/p/search"], { queryParams });
    }
  }

  removeVendorTag() {
    this.vendorService.removeVendor();
    this.displayGroupService.getDisplayGroups();
    this.generalService.setProductTreeState = false;
    const params = { ...this.route.snapshot.queryParams };
    delete params.vendor;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
    });
    this.productService.setRefetchProductListing();
  }

  changeDisplayType(): void {
    if (this.displayType === EDisplayType.Vendors) {
      this.displayType = EDisplayType.Display_Groups;
    } else {
      this.displayType = EDisplayType.Vendors;
    }
  }

  private findParentGroup(
    displayGroups: IDisplayGroups[],
    displayGroupCode: string
  ) {
    for (const item of displayGroups) {
      if (item.display_groups) {
        const found = item.display_groups.find(
          (group) => group.code == displayGroupCode && group.has_vendor === true
        );
        if (found) {
          return item;
        }
      }
    }
    return null; // Return null if not found
  }
}
