import { Injectable } from "@angular/core";

import { LocalStorageService, SessionStorageService } from "ngx-webstorage";

@Injectable()
export class CacheService {
  /**
   * Creates an instance of CacheService.
   * @param {LocalStorageService} localStorage
   * @memberof CacheService
   */
  constructor(
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService
  ) {}

  /**
   * this is to save value in
   * local storage
   * @param {string} key
   * @param {string | string[] | object} value
   * @memberof CacheService
   */
  set(key: string, value: string | string[] | object, storageType?: string) {
    const _value = JSON.stringify(value);
    if (storageType === "session") {
      this.sessionStorage.store(key, _value);
      return;
    }
    this.localStorage.store(key, _value);
  }

  /**
   * this is to retrieve the value by its name
   * @param {string} key
   * @returns
   * @memberof CacheService
   */
  get(key: string, storageType?: string) {
    let value = this.localStorage.retrieve(key);
    if (storageType === "session") {
      value = this.sessionStorage.retrieve(key);
    }
    return JSON.parse(value);
  }

  /**
   * this is to delete the stored value by key
   * @param {string} key
   * @memberof CacheService
   */
  remove(key: string, storageType?: string) {
    if (storageType === "session") {
      this.sessionStorage.clear(key);
    } else {
      this.localStorage.clear(key);
    }
  }

  /**
   * this is to delete all data in storage
   * @memberof CacheService
   */
  removeAll(storageType?: string) {
    if (storageType === "session") {
      this.sessionStorage.clear();
    } else {
      this.localStorage.clear();
    }
  }

  /**
   * this is to observe the change
   * in any stored values
   * by its key
   * @param {string} key
   * @returns
   * @memberof CacheService
   */
  observeOn(key: string, storageType?: string) {
    if (storageType === "session") {
      return this.sessionStorage.observe(key);
    }
    return this.localStorage.observe(key);
  }
}
