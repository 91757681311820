import { Injectable } from "@angular/core";
import { ApiService, CentralStorageService } from "src/app/core/services";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { IVendor } from "src/app/layout/vendor/ITypes";
import { map } from "rxjs/operators";
import { IWPContent } from "src/app/layout/home/ITypes";

@Injectable({
  providedIn: "root",
})
export class VendorService {
  vendor: IVendor = null;
  vendors: IVendor[] = [];

  private vendorsSubject = new BehaviorSubject<IVendor[]>([]);
  private vendorSubject = new BehaviorSubject<IVendor>(null);
  vendorsObs$ = this.vendorsSubject.asObservable();
  vendorObs$ = this.vendorSubject.asObservable();

  constructor(
    private api: ApiService,
    private centralStorageService: CentralStorageService
  ) {}

  getVendors(): IVendor[] {
    return this.vendors;
  }

  setVendors(vendors: IVendor[]): void {
    this.vendors = vendors;
    this.vendorsSubject.next(vendors);
  }

  getVendor(): IVendor {
    return this.vendor;
  }

  setVendor(vendor: IVendor): void {
    this.vendor = vendor;
    this.centralStorageService.setVendorCode(vendor.code);
    this.vendorSubject.next(vendor);
  }

  removeVendor(): void {
    this.vendor = null;
    this.centralStorageService.setVendorCode(null);
    this.vendorSubject.next(null);
  }

  loadVendors(): Observable<IVendor[]> {
    return this.api.get("/vendors").pipe(map((response) => response.result));
  }

  async loadVendorLandingPage(
    pageSlug: string
  ): Promise<Observable<IWPContent>> {
    return this.api
      .get(`/wp/vendors/${pageSlug}`)
      .pipe(map((response) => response.result));
  }
}
