import { Component, OnInit } from "@angular/core";

@Component({
  selector: "sv-cart-product-loader",
  templateUrl: "./cart-product-loader.component.html",
  styleUrls: ["./cart-product-loader.component.scss"],
})
export class CartProductLoaderComponent implements OnInit {
  // defaults
  nums = Array.from(Array(2).keys());

  constructor() {}

  ngOnInit(): void {}
}
