<div *ngIf="!emailBox">
  <!-- new login form  -->
  <div class="login-form">
    <div class="c-formItem">
      <div class="c-formGroup">
        <label class="c-formGroup__label">
          <!-- Login -->
          <span *ngIf="generalService.translationMode === true"> (G008) </span>
          {{ "GLOBAL.G008.value" | translate }}
        </label>
        <div class="c-formGroup__control">
          <input
            type="email"
            class="c-input"
            id="newEmail"
            aria-describedby="emailHelp"
            [(ngModel)]="authObj.email"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
      </div>
    </div>
    <div class="c-formItem">
      <div class="c-formGroup">
        <label class="c-formGroup__label">
          <!-- Password -->
          <span *ngIf="generalService.translationMode === true"> (G005) </span>
          {{ "GLOBAL.G005.value" | translate }}
        </label>
        <div class="c-formGroup__control">
          <input
            type="password"
            class="c-input"
            id="reg_password"
            [(ngModel)]="authObj.pass"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
      </div>
    </div>

    <small class="c-formGroup__helpText"> {{ errors.loginError }} </small>

    <div class="c-formItem">
      <button
        class="b-action b--lg b--wide"
        [disabled]="loginBtnText === 'Signing in...' ? true : false"
        (click)="logInViaEmail()"
      >
        <span *ngIf="generalService.translationMode === true"> (G002) </span>
        {{ "GLOBAL.G002.value" | translate }}
        <!-- {{ loginBtnText }} -->
      </button>
    </div>

    <p class="c-modal__helperLinks">
      <a href="javascript:void(0)" (click)="showRegistration()">
        <!-- Register -->
        <span *ngIf="generalService.translationMode === true"> (G004) </span>
        {{ "GLOBAL.G004.value" | translate }}</a
      >
      |
      <a href="javascript:void(0)" (click)="forgotPasswordModal()">
        <!-- Forgot my password -->
        <span *ngIf="generalService.translationMode === true"> (G006) </span>
        {{ "GLOBAL.G006.value" | translate }}
      </a>
    </p>

    <ng-template [ngIf]="config.socialLogin.visible">
      <div class="c-textSeparator">
        <span class="c-textSeparator__text">
          <span *ngIf="generalService.translationMode === true"> (G027) </span>
          {{ "GLOBAL.G027.value" | translate }}
        </span>
      </div>

      <div class="c-formItem">
        <button
          class="b-link b--facebook b--lg b--wide"
          (click)="facebookAuth()"
        >
          <!-- Sign in with Facebook -->
          <span *ngIf="generalService.translationMode === true"> (A007) </span>
          {{ "AUTH.A007.value" | translate }}
        </button>
      </div>
      <div class="c-formItem">
        <button class="b-link b--google b--lg b--wide" (click)="googleAuth()">
          <!-- Sign in with Google -->
          <span *ngIf="generalService.translationMode === true"> (A006) </span>
          {{ "AUTH.A006.value" | translate }}
        </button>
      </div>
    </ng-template>
  </div>
  <br />
  <small class="c-formGroup__helpText" *ngIf="errors.socialLoginError">
    here ?
    <!-- Social login errors -->
    {{
      errors.socialLoginError &&
      errors.socialLoginError === errors.popUpClosedError
        ? ("AUTH.A008.value" | translate)
        : ""
    }}
    {{
      errors.socialLoginError &&
      errors.socialLoginError === errors.userCancelledPopupError
        ? ("AUTH.A009.value" | translate)
        : ""
    }}
    {{
      errors.socialLoginError &&
      errors.socialLoginError === errors.anotherPopupError
        ? ("AUTH.A010.value" | translate)
        : ""
    }}
  </small>
</div>
