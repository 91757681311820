import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Title, Meta, MetaDefinition } from "@angular/platform-browser";

import nlp from "compromise";

import { IProduct } from "src/app/commons/product-card/IProduct";
import { IHrefLang } from "../ITypes";
import {SiteConfigService} from "./site-config.service";

/**
 * Represents
 * SEO Service
 * @export
 * @class SeoService
 */
@Injectable({
  providedIn: "root"
})
export class SeoService {
  // default
  private data: IProduct;

  /**
   * Creates an instance of SeoService.
   * @param {*} dom
   * @param {Title} titleService
   * @param {Meta} metaTagService
   * @memberof SeoService
   */
  constructor(
    @Inject(DOCUMENT) private dom,
    private titleService: Title,
    private metaTagService: Meta
  ) {}

  /**
   * sets the meta data
   * for product
   * @name setMetaData
   * @memberof SeoService
   */
  set setMetaData(data: IProduct) {
    this.data = data;
  }

  setFaceBookMeta(title: string, imgPath?:string) {

    this.metaTagService.removeTag("name='og:title'");
    this.metaTagService.removeTag("property='og:title'");
    this.metaTagService.removeTag("name='og:image'");
    this.metaTagService.removeTag("property='og:image'");

    this.metaTagService.addTags([
      { property: 'og:title', content: title },
      { property: 'og:image', content: imgPath },

    ]);
  }

  /**
   * sets the meta title
   * for page
   * @name setTitle
   * @memberof SeoService
   */
  set setTitle(title: string) {
    try {
      this.titleService.setTitle(title);
    } catch (error) {
      console.log("error in setTitle: ", error);
    }
  }

  /**
   * sets meta tags
   * for page
   * @name setMetaTags
   * @memberof SeoService
   */
  set setMetaTags(data: Array<MetaDefinition>) {
    try {
      this.metaTagService.addTags(data);
    } catch (error) {
      console.log("error in setMetaTags: ", error);
    }
  }

  /**
   * sets default
   * href language
   * @name setDefaultHreflang
   * @memberof SeoService
   */
  set setDefaultHreflang(url: string) {
    try {
      const defURL = url == undefined ? this.dom.URL : url;
      const link: HTMLLinkElement = this.dom.createElement("link");
      link.setAttribute("rel", "alternate");
      link.setAttribute("hreflang", "x-default");
      link.setAttribute("href", defURL);
      this.dom.head.appendChild(link);
    } catch (error) {
      console.log("error in setDefaultHreflang: ", error);
    }
  }

  /**
   * sets the alternate
   * href languages
   * @name setAlternateHreflang
   * @memberof SeoService
   */
  set setAlternateHreflang(data: Array<IHrefLang>) {
    try {
      if (!data.length) {
        return;
      }
      for (let i = 0; i < data.length; i++) {
        const d = data[i];
        const link: HTMLLinkElement = this.dom.createElement("link");
        link.setAttribute("rel", "alternate");
        link.setAttribute("hreflang", d.hreflang);
        link.setAttribute("href", d.href);
        this.dom.head.appendChild(link);
      }
    } catch (error) {
      console.log("error in setAlternateHreflang: ", error);
    }
  }

  updateMetaDescription(description: string) {
    this.metaTagService.removeTag("name='description'");
    this.setMetaTags = [
      { name: "description", content: description }
    ];
  }

  /**
   * sets the canonical url
   * for page
   * @name setCanonicalURL
   * @memberof SeoService
   */
  set setCanonicalURL(url: string) {
    try {
      const canURL = !url ? this.generateCanonicalUrl() : url;
      // tslint:disable-next-line:no-console
      console.debug('canUrl', canURL);
      const link: HTMLLinkElement = this.dom.createElement("link");
      link.setAttribute("rel", "canonical");
      link.setAttribute("href", canURL);
      this.dom.head.appendChild(link);
    } catch (error) {
      console.error("error in setCanonicalURL: ", error);
    }
  }

  generateCanonicalUrl(protocol = 'https', useWWW = false): string {
    let url = '';
    try {
      url += protocol + '://';
      url += (useWWW ? 'www' : '');
      const currentLocation = new URL(this.getCurrentUrl);
      url += currentLocation.host;
      url += currentLocation.pathname;
    } catch (error) {
      console.error("error in generateCanonicalUrlg(): ", error);
    }
    return url;
  }

  /**
   * gets the current url
   * @readonly
   * @name getCurrentUrl
   * @memberof SeoService
   */
  get getCurrentUrl() {
    return this.dom.URL;
  }

  /**
   * gets the meta title
   * @readonly
   * @name getMetaTitle
   * @memberof SeoService
   */
  get getMetaTitle() {
    try {
      const d = this.data;
      if (!Object.keys(d).length) {
        return "";
      }
      return `${d.F001} ${d.F002} ${d.F005}`;
    } catch (error) {
      console.log("error in getMetaTitle: ", error);
    }
  }

  /**
   * gets the meta description
   * @readonly
   * @name getMetaDescription
   * @memberof SeoService
   */
  get getMetaDescription() {
    try {
      const d = this.data;
      if (!Object.keys(d).length) {
        return "";
      }
      return d.F003;
    } catch (error) {
      console.log("error in getMetaDescription: ", error);
    }
  }

  /**
   * gets the meta keywords
   * @readonly
   * @name getMetaKeywords
   * @memberof SeoService
   */
  get getMetaKeywords() {
    try {
      const d = this.data;
      if (!Object.keys(d).length) {
        return "";
      }
      const parsedDesc = this.removeVerbs(d.F001);
      return `${parsedDesc} ${d.D001} ${d.C002}`;
    } catch (error) {
      console.log("error in getMetaKeywords: ", error);
    }
  }

  /**
   * remove verbs from a string
   * with the help of nlp
   * @private
   * @param {string} value
   * @returns
   * @name removeVerbs
   * @memberof SeoService
   */
  private removeVerbs(value: string) {
    try {
      if (!value) {
        return;
      }
      let str = nlp(value);
      let removables = str.verbs().out("text");
      return str.delete(removables).out("text");
    } catch (error) {
      console.log("error in removeVerbs: ", error);
    }
  }
}
