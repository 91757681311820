import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "shopCurrency"
})
export class ShopCurrencyPipe implements PipeTransform {
  transform(value: any): string {
    let strToReplace = "[.]";
    let replacementStr = ",";

    value += "";
    if (!value || !strToReplace || !replacementStr) {
      return value;
    }

    return value.replace(new RegExp(strToReplace, "g"), replacementStr) + " €";
  }
}

@Pipe({
  name: "shopCurrencyDigits"
})
export class ShopCurrencyDigitsPipe implements PipeTransform {
  transform(value: any): string {
    let strToReplace = "[.]";
    let replacementStr = ",";

    value += "";
    if (!value || !strToReplace || !replacementStr) {
      return value;
    }

    return value.replace(new RegExp(strToReplace, "g"), replacementStr);
  }
}
